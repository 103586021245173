<template>
	<div>
		<v-system-bar
			app
			dark
			color="error"
			class="text-center white--text"
			v-if="NetworkOffline"
		>
			<v-spacer/>
			<v-icon left >mdi-network-off</v-icon> Störningar har upptäckts i din internetanslutning <v-btn class="ml-2" icon loading x-small><v-icon x-small>mdi-loading</v-icon></v-btn>
			<v-spacer/>
		</v-system-bar>

		<CreateNewYearDialog ref="createNewYearDialog"/>
		<DeleteSalaryYearsDialog ref="deleteSalaryYearsDialog"/>

		<v-app-bar
						app
						color="primary"
						dark
						fixed
						clipped-left
						flat
						dense
						:style="computedAppStyle"
		>
			<v-toolbar-title style="width: 300px">
				<v-row dense>
					<v-col cols="1">
						<v-app-bar-nav-icon @click.stop="$eventBus.$emit('toggleNavigation')"></v-app-bar-nav-icon>
					</v-col>
					<v-col cols="11">
						<v-img
							:src="$buildBrandedAssetsURL('/logos/white_long.png')"
							:lazy-src="$buildBrandedAssetsURL('/logos/white_long.png')"
							width="155"
							height="45"
							contain
							class="ml-4"
						/>
					</v-col>
				</v-row>
			</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-menu
							v-model="notificationsMenu"
							:close-on-content-click="false"
							offset-y
							:max-height="300"
			>
				<template v-slot:activator="{on}">
					<v-badge left :color="UnreadNotifications > 0 ? 'red' : 'transparent'" class="align-self-center" :offset-y="10">
						<template v-slot:badge>
							<span v-if="UnreadNotifications > 0">{{UnreadNotifications}}</span>
						</template>
						<v-btn icon v-on="on" x-small class="pr-0 ma-0">
							<v-icon>mdi-bell</v-icon>
						</v-btn>
					</v-badge>
				</template>

				<v-card>
					<v-list dense class="pt-0 pb-0">
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title><Title bold small>Notiser</Title></v-list-item-title>
								<v-list-item-subtitle v-if="Notifications.length === 0">Du har inga notiser just nu</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								<v-btn icon @click="notificationsMenu = false"><v-icon>mdi-close</v-icon></v-btn>
							</v-list-item-action>
						</v-list-item>
					</v-list>

					<v-divider v-if="Notifications.length > 0"></v-divider>

					<v-list dense v-if="Notifications.length > 0">
						<template v-for="(item) in Notifications">
							<template v-if="item.RouteConfig">
								<v-list-item @click="onClickNotification(item)">
									<v-list-item-avatar>
										<v-icon :color="item.Icon.Color">{{item.Icon.Type}}</v-icon>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title class="text-truncate" :class="{'font-weight-bold': !item.HasRead}">{{item.Title}} {{item.FtgNamn ? ` - ${item.FtgNamn}` : ''}}</v-list-item-title>
										<v-list-item-subtitle class="text-truncate" :class="{'font-weight-bold': !item.HasRead}">{{item.Message}}</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action class="font-weight-light"  style="font-size: 14px;">
										{{printTime(item.TimeStamp)}}
									</v-list-item-action>
								</v-list-item>
							</template>
							<template v-else>
								<v-list-item>
									<v-list-item-avatar>
										<v-icon :color="item.Icon.Color">{{item.Icon.Type}}</v-icon>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title class="text-truncate" :class="{'font-weight-bold': !item.HasRead}">{{item.Title}} {{item.FtgNamn ? ` - ${item.FtgNamn}` : ''}}</v-list-item-title>
										<v-list-item-subtitle class="text-truncate" :class="{'font-weight-bold': !item.HasRead}">{{item.Message}}</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action class="font-weight-light"  style="font-size: 14px;">
										{{printTime(item.TimeStamp)}}
									</v-list-item-action>
								</v-list-item>
							</template>
						</template>
					</v-list>
				</v-card>
			</v-menu>

			<Tooltip text="Meddelanden">
				<v-badge :color="UnreadMessagesCount > 0 ? 'red' : 'transparent'" class="align-self-center" :offset-y="15" :offset-x="10">
					<template v-slot:badge>
						<span v-if="UnreadMessagesCount > 0">{{UnreadMessagesCount}}</span>
					</template>
					<v-btn to="/messages" icon small class="pr-0 ml-2 ma-0">
						<v-icon>mdi-inbox</v-icon>
					</v-btn>
				</v-badge>
			</Tooltip>

			<v-menu
							v-model="yearMenuOpen"
							:close-on-content-click="false"
							offset-y
							:max-height="300"
							open-on-hover
							v-if="SalaryYear"
			>
				<template v-slot:activator="{on}">
					<v-btn class="pa-0" small text v-on="on">{{SalaryYear}}</v-btn>
				</template>

				<v-card>
					<v-list dense class="pb-0">
						<template v-if="UserRights.hasSalaryYearCreateNewAccess()">
						<v-list-item class="pt-0 mt-0">
							<v-list-item-content class="pt-0 mt-0">
								<v-list-item-title style="height: auto;" class="pt-0 mt-0">
									<Tooltip text="Skapa nytt löneår">
										<v-btn small color="accent" icon @click="$refs.createNewYearDialog.show()"><v-icon>mdi-plus</v-icon></v-btn>
									</Tooltip>
									<Tooltip text="Rensa gamla löneår">
										<v-btn small color="accent" icon @click="$refs.deleteSalaryYearsDialog.show()"><v-icon>mdi-delete-clock</v-icon></v-btn>
									</Tooltip>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-divider></v-divider>
						</template>

						<template v-for="(year, index) in SalaryYears">
							<v-list-item
											:key="`${year}_${index}`"
											@click="onSelectYear(year)"
							>
								<v-list-item-content>
									<v-list-item-title class="text-center">{{year}}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>
					</v-list>
				</v-card>
			</v-menu>

			<v-menu
							v-model="userMenuOpen"
							:close-on-content-click="false"
							offset-y
							:max-height="380"
							open-on-hover
			>
				<template v-slot:activator="{on}">
					<v-btn v-on="on" small text class="hidden-xs-only pa-0 pr-1 pl-1">
						<v-icon v-if="$isBirthday(UserAccount.PersNr)" color="red" small>mdi-balloon</v-icon>
						{{UserAccount.Namn}}
						<v-icon>{{userMenuOpen ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
					</v-btn>
					<v-btn v-on="on" small icon class="hidden-sm-and-up mr-2"><v-icon>mdi-account</v-icon></v-btn>
				</template>

				<v-card class="pb-0 pt-0">
					<v-list dense class="pb-0 pt-0">
						<v-list-item>

							<v-list-item-content>
								<v-list-item-title><v-icon v-if="$isBirthday(UserAccount.PersNr)" color="red" small>mdi-balloon</v-icon>{{UserAccount.Namn}}</v-list-item-title>
								<v-list-item-subtitle>{{translateRoll(UserAccount.Roll)}}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>

						<v-list-item to="/mySettings">
							<v-list-item-action>
								<v-icon>mdi-cog-outline</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>Inställningar</v-list-item-title>
								<v-list-item-subtitle>Mina preferenser</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>

						<v-list-item @click="onClickChangePassword">
							<v-list-item-action>
								<v-icon>mdi-lock-reset</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>Byt lösenord</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item @click="toggleTheme">
							<v-list-item-action>
								<v-icon :color="$vuetify.theme.dark ? 'white' : 'darkgrey'">mdi-theme-light-dark</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>Tema</v-list-item-title>
								<v-list-item-subtitle>{{`Växla till ${$vuetify.theme.dark ? 'ljust' : 'mörkt'} tema`}}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>

						<v-list-item @click="onClickLicNr">
							<v-list-item-action>
								<v-icon :color="$vuetify.theme.dark ? 'white' : 'darkgrey'">mdi-license</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>Licensnummer</v-list-item-title>
								<v-list-item-subtitle>{{UserAccount.LicNr}}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>

						<v-list-item @click="$openNewTab('https://fsdl.crona.se/Dokument/Avtal/Allmannavillkor.pdf', {skipBaseURI: true})">
							<v-list-item-action>
								<v-icon :color="$vuetify.theme.dark ? 'white' : 'darkgrey'">mdi-file-document-outline</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>Allmäna villkor</v-list-item-title>
								<v-list-item-subtitle>Crona Software AB</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>

					<v-divider></v-divider>


					<v-card-actions class="pa-0 ma-0">
						<v-btn style="width: 100%;" text @click="userMenuOpen = false">Stäng</v-btn>
					</v-card-actions>
				</v-card>
			</v-menu>

			<Tooltip text="Logga ut">
				<v-btn class="ml-2" icon @click="onLogout" small><v-icon>mdi-logout</v-icon></v-btn>
			</Tooltip>

		</v-app-bar>

		<ChangePasswordDialog ref="changePasswordDialog"/>
		<LicensInfoDialog ref="licensInfoDialog"/>
	</div>
</template>

<script>
import {mapState, mapActions}  from 'vuex';
import moment                  from 'moment';
import LocalStorage            from '../lib/LocalStorage.js';
import Tooltip                 from './Tooltip.vue';
import ChangePasswordDialog    from './ChangePasswordDialog.vue';
import Title                   from './typography/Title.vue';
import CreateNewYearDialog     from './CreateNewYearDialog.vue';
import LicensInfoDialog        from './LicensInfoDialog.vue';
import DeleteSalaryYearsDialog from './DeleteSalaryYearsDialog.vue';

moment.locale('sv');

export default {
	name: 'AppToolbar',

	data: () => ({
		selectCompanyMenuOpen: false,
		dialog:                false,
		userMenuOpen:          false,
		yearMenuOpen:          false,
		notificationsMenu:     false,
	}),

	mounted() {
		window.addEventListener('online', () => {
			this.toggleNetworkOffline(false);
		}, false);

		window.addEventListener('offline', () => {
			this.toggleNetworkOffline(true);
		}, false);
	},

	computed: {
		...mapState(['FtgInfo', 'UserAccount', 'Notifications', 'SalaryYears', 'SalaryYear', 'UnreadMessagesCount', 'UserRights', 'NetworkOffline']),

		UnreadNotifications() {
			return this.Notifications.filter((item) => !item.HasRead).length;
		},

		computedAppStyle() {
			let borderBottom = '1px solid rgba(0, 0, 0, 0.12)';

			if(this.$vuetify.theme.dark) {
				borderBottom = '1px solid rgba(255, 255, 255, 0.24)';
			}

			return {
				'border-bottom': borderBottom,
			};
		},
	},

	watch: {
		notificationsMenu(value) {
			if(value === false) {
				this.$tryCatch({
					task: async () => {
						await this.$http.put('/notifications/markAllAsRead');

						for(const item of this.Notifications) {
							item.HasRead = true;
						}
					},
				});
			}
		},
	},
		
	methods: {
		...mapActions(['clearNotifications', 'logout', 'setSalaryYear', 'confirmDialog', 'addNotification', 'toggleNetworkOffline']),

		onClickLicNr() {
			if(this.UserAccount.Roll === 'LIC') {
				this.$refs.licensInfoDialog.show();

				return;
			}

			if(this.clipboardAPIEnabled) {
				this.copyTextToClipboard(this.UserAccount.LicNr);
				this.$msgSuccess('Licensnummer kopierat');
			}
		},

		onClickChangePassword() {
			this.$refs.changePasswordDialog.show();
		},

		toggleTheme() {
			const newValue = !this.$vuetify.theme.dark;

			LocalStorage.setDarkModePreference(newValue);

			this.$vuetify.theme.dark = newValue;
		},

		translateRoll(Roll) {
			const rollMap = {
				LIC: 'Licensanvändare',
				ADM: 'Systemadministratör',
				FTG: 'Företagsadministratör',
				LSS: 'Kundansvarig',
				BRU: this.$formatter.brukareNamnTypText('Brukare'),
				AVD: 'Avdelningsansvarig',
				PER: 'Anställd',
			};

			return rollMap[Roll] || Roll;
		},

		onClickNotification(item) {
			if(item.RouteConfig) {
				if(item.RouteConfig.isExternal) {
					this.$openNewTab(item.RouteConfig.url, {skipBaseURI: true})
				} else {
					this.$router.push(item.RouteConfig);
				}
				this.notificationsMenu = false;
			}
		},

		onSelectYear(year) {
			this.setSalaryYear({
				Year: year,
			});
			this.yearMenuOpen = false;
		},

		printTime(value) {
			return moment(value).fromNow();
			// return moment(value).format('ddd DD MMM HH:mm');
		},

		onLogout() {
			this.userMenuOpen = false;

			this.logout();
		},
	},

	components: {
		DeleteSalaryYearsDialog,
		LicensInfoDialog,
		CreateNewYearDialog,
		Title,
		ChangePasswordDialog,
		Tooltip,
	},
}
</script>

<style scoped>
	::v-deep(.v-toolbar__content) {
		padding-left: 5px;
		padding-right: 5px;
	}
</style>

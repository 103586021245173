<template>
	<v-slide-x-transition hide-on-leave>
		<v-text-field
						v-model="dataModel[name]"
						:rules="model.$rules[name]"
						v-bind="$attrs"
						v-on="$listeners"
						type="text"
						:data-name="name"
						:data-tabordername="tabOrderName || name"
						:label="noLabel ? '' : label ? label : model.constructor.propertyMap[name].label"
						@keydown="$emit('keydown', $event)"
						@keydown.native="$checkForTabOrderEvent($event)"
						@keyup="$emit('keyup', $event)"
						ref="field"
						validate-on-blur
						@focus="onFocus($event)"
						@blur="onBlur($event)"
						:counter="fieldCounter"
						autocomplete="off"
						color="accent"
						persistent-placeholder
		>
			<template v-slot:append="">
				<slot name="append"></slot>
			</template>
		</v-text-field>
	</v-slide-x-transition>
</template>

<script>
import {get} from 'lodash';

export default {
	name: 'InputField',

	props: {
		model: {
			required: true,
		},
		name: {
			required: true,
		},
		tabOrderName: {
			type:    String,
			default: '',
		},
		noLabel: {
			type:    Boolean,
			default: false,
		},
		label: {
			type:    String,
			default: '',
		},
		counter: {
			default: false,
		},
		forcePlaceholder: {
			type:    Boolean,
			default: false,
		},
	},

	data: () => ({
		fieldCounter: false,
	}),

	methods: {
		focus() {
			this.$refs.field.focus();
		},

		blur() {
			this.$refs.field.blur();
		},

		onFocus() {
			this.$eventBus.$emit('Help:SetFromFieldFocus', {
				model: this.model,
				name:  this.name,
			});
			// this.$emit('focus', event);

			this.fieldCounter = this.counter;
		},

		onBlur() {
			if(this.model[this.name]) {
				this.model[this.name] = this.model[this.name].trim();
			}

			this.resetValidation();

			// this.$emit('blur', event);

			this.fieldCounter = false;
		},

		validate() {
			this.$refs.field.validate();
		},

		selectAllContent() {
			try {
				const $input = get(this.$refs, 'field.$refs.input', null);

				if($input) {
					$input.select();
					$input.setSelectionRange(0, ($input.value || '').length)
				}
			} catch{}
		},

		resetValidation() {
			this.$refs.field.resetValidation();
		},
	},

	computed: {
		dataModel() {
			return this.forcePlaceholder ? {[this.name]: ''} : this.model;
		},
	},
}
</script>

<style scoped>

</style>

<template>
	<v-avatar @click="$emit('click')" v-if="item && item.PictureId" :size="hasPicture ? 'auto' : sizeNoImage || size" class="text-right" :color="hasPicture || loading ? void 0 : 'primary'" :class="{'cursor-pointer': hasPicture && canEdit}" :tile="hasPicture ? tile: false">
		<v-hover v-if="hasPicture" v-slot:default="{hover}">
			<v-img
				:src="$buildURL(`/ftgpicture/${encodeURIComponent(item.FtgId)}/${encodeURIComponent(item.PictureId)}`, {apiRoute: true})"
				:lazy-src="$buildURL(`/ftgpicture/${encodeURIComponent(item.FtgId)}/${encodeURIComponent(item.PictureId)}`, {apiRoute: true})"
				:max-width="size"
				:max-height="size"
				contain
			>
				<v-row dense class="fill-height ma-0"
					slot="placeholder"
					align="center"
					justify="center"
				>
					<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
				</v-row>

				<v-fade-transition hide-on-leave>
					<div style="position: absolute; top: 0; left: 0; height: 100%;" v-if="hover && canEdit">
						<v-btn :width="size" style="height: 100%;" outlined color="accent" @click="$refs.editDialog.show()"><v-icon>mdi-pencil</v-icon></v-btn>
					</div>
				</v-fade-transition>
			</v-img>
		</v-hover>

		<v-btn v-else-if="canEdit && !loading" icon :width="size" :height="size" color="white" @click="$refs.editDialog.show()"><v-icon>mdi-camera-plus</v-icon></v-btn>

		<span v-else-if="!loading" class="white--text" :class="{[spanclass]: true}">{{getNamnInitials(item)}}</span>

		<PictureEditDialog
						ref="editDialog"
						v-if="item"
						:item="item"
						title="Företagsbild"
						:save-handler="saveFtgPicture"
						:delete-handler="deleteFtgPicture"
		>
			<template slot="text">
				<p v-if="FtgInfo.PrintoutPayslipBlankett === 'standard'">Företagsbild som laddas upp syns endast i {{SupplierInfo.SoftwareName}}.</p>
				<p v-if="FtgInfo.PrintoutPayslipBlankett === 'logotyp'">Företagsbild som laddas upp syns både i {{SupplierInfo.SoftwareName}} och vid utskrift av lönebesked.</p>
				<p>Bilden ska vara av png eller jpeg format och maximala tillåtna storleken är 5mb.</p>
			</template>
		</PictureEditDialog>
	</v-avatar>
</template>

<script>
import {mapState}        from 'vuex';
import PictureEditDialog from '../PictureEditDialog.vue';

export default {
	name:       'CompanyTableAvatar',
	components: {PictureEditDialog},
	props:      {
		item: {
			required: true,
			type:     Object,
		},

		size: {
			type:    String,
			default: '48px',
		},

		sizeNoImage: {
			type:    String,
			default: '',
		},

		tile: {
			type:    Boolean,
			default: true,
		},

		loading: {
			type:    Boolean,
			default: false,
		},

		spanclass: {
			type:    String,
			default: 'caption',
		},

		canEdit: {
			type:         Boolean,
			defaultValue: false,
		},
	},

	methods: {
		onUpdated(Id) {
			this.$emit('updated', Id);

			this.FtgInfo.PictureId = Id;
		},

		getNamnInitials(item) {
			const {Namn, FtgId} = item;

			if(Namn) {
				const first = Namn.slice(0, 1);
				const second = ([...Namn.slice(1)].find((value) => value === value.toUpperCase()) || '');

				return [first.toUpperCase(), second.toUpperCase()].join('');
			}

			return FtgId.slice(0, 2).toUpperCase();
		},

		async saveFtgPicture(data) {
			const {Id} = await this.$http.put(`/ftgpicture`, data);

			this.$emit('updated', Id);
			this.item.PictureId = Id;

			return true;
		},

		async deleteFtgPicture() {
			await this.$http.delete(`/ftgpicture`);

			this.$emit('updated', '00000000-0000-0000-0000-000000000000');
			this.item.PictureId = '00000000-0000-0000-0000-000000000000';

			return true;
		},
	},

	computed: {
		...mapState(['FtgInfo', 'SupplierInfo']),

		hasPicture() {
			return this.item && this.item.PictureId !== '00000000-0000-0000-0000-000000000000';
		},
	},
}
</script>

<style scoped>

</style>

/**
 * Created by simon on 2020-04-20.
 */

import {sortBy as _sortBy, toNumber, compact, isArray, includes, chunk, filter, escapeRegExp, find, toLower} from 'lodash';
import LocalStorage                                                                                          from './LocalStorage.js';
import HttpRequester                                                                                         from './HttpRequester.js';

class PaginationHelper {
	constructor({
		sortBy,
		appendSort = null,
		sortOrder = null,
		descending = false,
		perPage = LocalStorage.get('PaginationPerPage', 25),
		pageNum = 1,
		searchTerm = '',
		searchFields = [],
		localStorageDescendingPrefix = null,
	} = {}) {
		this.localStorageDescendingPrefix = localStorageDescendingPrefix;

		if(this.localStorageDescendingPrefix) {
			descending = LocalStorage.get(this.localStorageDescendingPrefix, descending)
		}

		for(const item of searchFields) {
			item.selected = item.selected || false;
		}

		if(searchFields.length > 0 && !searchFields.find(({selected}) => selected)) {
			searchFields[0].selected = true;
		}

		this.sortBy            = compact(isArray(sortBy) ? sortBy : [sortBy]);
		this.explicitSortOrder = compact(isArray(sortOrder) ? sortOrder : [sortOrder]);
		this.descending        = descending;
		this.perPage           = perPage;
		this.pageNum           = pageNum;
		this.totalRows         = perPage === -1 ? -1 : 0;
		this.searchTerm        = searchTerm;
		this.searchFields      = searchFields;
		this.appendSort        = appendSort;
		this.sortNumericFields = [];
	}

	static get itemsPerPageOptions() {
		return [
			{
				text:  '25',
				value: 25,
			},
			{
				text:  '50',
				value: 50,
			},
			{
				text:  '75',
				value: 75,
			},
			{
				text:  '100',
				value: 100,
			},
		];
	}

	toQueryFilter() {
		const searchFields = this.searchFields.filter(({selected}) => selected).map(({fields}) => fields).flat()
		const searchTerm = searchFields.length > 0 && this.searchTerm && this.searchTerm.trim() ? this.searchTerm : undefined;
		const appendSort = this.appendSort ? this.appendSort.filter((value) => !includes(this.sortBy, value)) : [];

		const sortBy = [...this.sortBy, ...appendSort];

		const sortOrder = [...this.explicitSortOrder];
		const ascOrDesc = this.descending ? 'desc' : 'asc';

		while(sortOrder.length !== sortBy.length) {
			sortOrder.push(ascOrDesc);
		}

		LocalStorage.set('PaginationPerPage', this.perPage || 25);

		return {
			sortBy,
			sortOrder,
			perPage:      this.perPage === -1 ? undefined : this.perPage,
			pageNum:      this.perPage === -1 ? undefined : this.pageNum,
			searchTerm,
			searchFields: searchTerm ? searchFields : undefined,
		};
	}

	setTotalRows(totalRows) {
		if(totalRows || totalRows === 0) {
			this.totalRows = totalRows;
		}
	}

	setSortNumericFields(fields) {
		this.sortNumericFields = fields;
	}

	async fetch(url, options = {params: {}}) {
		options.params = options.params || {};
		options.params.pagination = JSON.stringify(this.toQueryFilter());

		const result = await (options.method && ['post', 'put'].includes(options.method) ? HttpRequester[options.method](url, options.data, options) : HttpRequester.get(url, options));

		const {PaginationInfo} = result;

		this.setTotalRows(PaginationInfo.totalRows);

		return result;
	}

	setSortField(sortField) {
		if(includes(this.sortBy, sortField)) {
			this.descending = !this.descending;

			if(this.localStorageDescendingPrefix) {
				LocalStorage.set(this.localStorageDescendingPrefix, this.descending);
			}
		} else {
			this.sortBy = [sortField];
		}
	}

	paginateRaw(items) {
		const {sortBy, descending, perPage, pageNum, searchTerm} = this;
		const searchFields = (this.searchFields || []).filter((item) => item.selected).map(({fields}) => fields).flat();

		if(searchTerm && searchTerm.trim()) {
			const search = new RegExp(escapeRegExp(toLower(searchTerm)).split(/ |-/).reduce((a, b) =>  `${a}.*${b}`));

			items = filter(items, (item) =>
				find(searchFields, (field) => search.test(toLower(item[field])))
			);
		}

		let sorted = _sortBy(items, sortBy.map((fieldName) =>
			this.sortNumericFields.includes(fieldName) ? (val) => toNumber(val) : fieldName
		));

		if(descending) {
			sorted = sorted.reverse();
		}

		const chunks = perPage > -1 ? chunk(sorted, perPage) : [sorted];

		this.setTotalRows(items.length);

		return chunks[pageNum - 1] || [];
	}
}

export default PaginationHelper;

/**
 * Created by simon on 2022-02-07.
 */

import _ from 'lodash';

class UserRights {
	constructor(data = {}) {
		this.Id      = '';
		this.BenText = '';

		this.SalaryYear = {
			CreateNew: false,
		};

		this.SendCompanyCopy = {
			Enabled: false,
		};

		this.Infopanel = {
			LonDashboardStats: false,
		};

		this.Kalendarie = {
			Read:  false,
			Write: false,
		};

		this.Tidrapport = {
			Read:  false,
			Write: false,
		};

		this.Ersattningar = {
			Read:           false,
			Write:          false,
			Resereglemente: {
				Write: false,
			},
		};

		this.FOS = {
			Read: false,
			Upd:  false,
		};

		this.Payslips = {
			Read:        false,
			ImportBasis: false,
			Write:       false,
			Attest:      false,
			Publish:     false,
			Payout:      false,
		};

		this.EmployerDeclaration = {
			Read:   false,
			Write:  false,
			Export: false,
			Send:   false,
		};

		this.Bookkeeping = {
			Read:   false,
			Write:  false,
			Send:   false,
			Export: false,
		};

		this.Loneuppdrag = {
			Read:  false,
			Write: false,
		};

		this.FKSjukanmalan = {
			Read: false,
		};

		this.DataTreeSearch = {
			Read: false,
		};

		this.Semesterberedning = {
			Read:  false,
			Write: false,
			Upd:   false,
		};

		this.FerLon = {
			Read:  false,
			Write: false,
			Upd:   false,
		};

		this.UphLon = {
			Read:  false,
			Write: false,
			Upd:   false,
		};

		this.Kontrolluppgift = {
			Read:  false,
			Write: false,
		};

		this.Fora = {
			Read:   false,
			Write:  false,
			Export: false,
		};

		this.Collectum = {
			ITP1: {
				Read:   false,
				Write:  false,
				Export: false,
			},
			ITP2: {
				Read:   false,
				Write:  false,
				Export: false,
			},
		};

		this.SCB = {
			SLP: {
				Read:   false,
				Write:  false,
				Export: false,
			},
			KLP: {
				Read:   false,
				Write:  false,
				Export: false,
			},
			KSJU: {
				Read:   false,
				Write:  false,
				Export: false,
			},
			SUS: {
				Read:   false,
				Write:  false,
				Export: false,
			},
			KSP: {
				Read:  false,
				Write: false,
			},
		};

		this.SAF = {
			Read:   false,
			Write:  false,
			API:    false,
			Export: false,
		};

		this.KFO = {
			Read:   false,
			Write:  false,
			Export: false,
		};

		this.KP = {
			Read:   false,
			Write:  false,
			Export: false,
		};

		this.LOSEN = {
			Read:   false,
			Write:  false,
			Export: false,
		};

		this.ForskUtb = {
			Read:  false,
			Write: false,
		};

		this.LoneRevision = {
			Read:    false,
			Write:   false,
			Approve: false,
			Attest:  false,
		};

		this.Lonekartlaggning = {
			Read:            false,
			Arbetsvardering: false,
		};

		this.RetroLon = {
			Read:    false,
			Write:   false,
			Approve: false,
		};

		this.Narvarostatus = {
			Read: false,
		};

		this.Documents = {
			Read:  false,
			Write: false,
		};

		this.LSS = {
			Ber: {
				Read:  false,
				Write: false,
			},

			RapOmk: {
				Read:  false,
				Write: false,
			},

			RapErs: {
				Read:  false,
				Write: false,
			},

			Redov: {
				Read: false,
			},

			Aterrapportering: {
				Read: false,
			},

			KostnTyp: {
				Read:  false,
				Write: false,
			},

			Settings: {
				Read:  false,
				Write: false,
			},
		};

		this.Reports = {
			DebKonto: {
				Read: false,
			},

			BruttoloneLista: {
				Read: false,
			},

			Loneartslista: {
				Read: false,
			},

			Resultatrapport: {
				Read: false,
			},

			Lonestatistik: {
				Read: false,
			},

			Saldoskuld: {
				Read: false,
			},

			LASLista: {
				Read: false,
			},

			SINKLista: {
				Read: false,
			},

			PerLista: {
				Read: false,
			},

			BrukarLista: {
				Read: false,
			},

			PerReg: {
				Read: false,
			},

			PerKontakt: {
				Read: false,
			},

			Loneutmatning: {
				Read: false,
			},

			Loneutveckling: {
				Read: false,
			},

			FackAvg: {
				Read: false,
			},

			Rehablista: {
				Read: false,
			},

			TimeJournals: {
				Read: false,
			},
		};

		this.HR = {
			FTEHeadcount: {
				Read: false,
			},
		};

		this.Registry = {
			PerReg: {
				Read:                false,
				Write:               false,
				Export:              false,
				CalcVehicleBenefits: false,
				PerRegistryCare:     false,
				ArbGivIntyg:         false,
				ChangeAnstNr:        false,
				Franvaro:            false,
			},

			BrukarReg: {
				Read:          false,
				Write:         false,
				ChangeBrukare: false,
				BrukarAnsv:    {
					Read:  false,
					Write: false,
				},
			},

			ArbSchema: {
				Read:  false,
				Write: false,
			},

			OBRegler: {
				Read:  false,
				Write: false,
			},

			JorRegler: {
				Read:  false,
				Write: false,
			},

			BerRegler: {
				Read:  false,
				Write: false,
			},

			EldRegler: {
				Read:  false,
				Write: false,
			},

			HlgRegler: {
				Read:  false,
				Write: false,
			},

			OvtRegler: {
				Read:  false,
				Write: false,
			},

			Agreements: {
				Read:  false,
				Write: false,
			},

			VacationAgreements: {
				Read:  false,
				Write: false,
			},

			ArtReg: {
				Read:  false,
				Write: false,
			},

			CustomFormelVariables: {
				Read:  false,
				Write: false,
			},

			LonKoder: {
				Read:  false,
				Write: false,
			},

			TidKoder: {
				Read:  false,
				Write: false,
			},

			ImpReg: {
				Read:  false,
				Write: false,
			},

			KontoReg: {
				Read:  false,
				Write: false,
			},

			ResEnhet: {
				Read:  false,
				Write: false,
			},

			Fordelningsnycklar: {
				Read:  false,
				Write: false,
			},

			FriaAckarDef: {
				Read:  false,
				Write: false,
			},

			Egenskaper: {
				Read:  false,
				Write: false,
			},

			Varugrupper: {
				Read:  false,
				Write: false,
			},

			Departments: {
				Read:  false,
				Write: false,
			},

			FackFor: {
				Read:  false,
				Write: false,
			},

			Holidays: {
				Read:  false,
				Write: false,
			},

			SalaryPeriods: {
				Read:  false,
				Write: false,
			},
		};

		this.Settings = {
			UserRightsGroups: {
				Read:  false,
				Write: false,
			},

			Company: {
				Read:   false,
				Write:  false,
				Delete: false,
			},

			Bokforing: {
				Read:  false,
				Write: false,
			},

			Users: {
				Read:  false,
				Write: false,
			},

			Kortnummer: {
				Read:  false,
				Write: false,
			},

			Integrations: {
				Read:  false,
				Write: false,
			},

			SysData: {
				Read: false,
			},

			DigitalMailbox: {
				Read:  false,
				Write: false,
			},

			Printouts: {
				Read:  false,
				Write: false,
			},

			Export: {
				Read:  false,
				Write: false,
			},
		};

		for(const [key, value] of Object.entries(data)) {
			if(_.isObject(this[key])) {
				_.extend(this[key], value);
			} else {
				this[key] = value;
			}
		}
	}

	hasSalaryYearCreateNewAccess() {
		return this.SalaryYear.CreateNew;
	}

	hasSendCompanyCopyAccess() {
		return this.SendCompanyCopy.Enabled;
	}

	hasKalendarieReadAccess() {
		return this.Kalendarie.Read;
	}

	hasKalendarieWriteAccess() {
		return this.Kalendarie.Write;
	}

	hasTidrapportReadAccess({HasPlus}) {
		return this.Tidrapport.Read && HasPlus;
	}

	hasTidrapportWriteAccess({HasPlus}) {
		return this.Tidrapport.Write && HasPlus;
	}

	hasErsattningarReadAccess() {
		return this.Ersattningar.Read;
	}

	hasErsattningarWriteAccess() {
		return this.Ersattningar.Write;
	}

	hasResereglementeWriteAccess() {
		return this.Ersattningar.Resereglemente.Write;
	}

	hasFOSReadAccess() {
		return this.FOS.Read;
	}

	hasFOSUpdAccess() {
		return this.FOS.Upd;
	}

	hasLonDashboardStatsAccess({HasPlus}) {
		return this.Infopanel.LonDashboardStats && HasPlus;
	}

	hasPayslipsReadAccess() {
		return this.Payslips.Read;
	}

	hasPayslipsWriteAccess() {
		return this.Payslips.Write;
	}

	hasPayslipsAttestAccess() {
		return this.Payslips.Attest;
	}

	hasPayslipsPublishAccess() {
		return this.Payslips.Publish;
	}

	hasPayslipsPayoutAccess() {
		return this.Payslips.Payout;
	}

	hasPayslipsImportBasisAccess() {
		return this.Payslips.ImportBasis;
	}

	hasEmployerDeclarationReadAccess() {
		return this.EmployerDeclaration.Read;
	}

	hasEmployerDeclarationWriteAccess() {
		return this.EmployerDeclaration.Write;
	}

	hasEmployerDeclarationSendAccess() {
		return this.EmployerDeclaration.Send;
	}

	hasEmployerDeclarationExportAccess() {
		return this.EmployerDeclaration.Export;
	}

	hasBookkeepingReadAccess() {
		return this.Bookkeeping.Read;
	}

	hasBookkeepingWriteAccess() {
		return this.Bookkeeping.Write;
	}

	hasBookkeepingSendAccess() {
		return this.Bookkeeping.Send;
	}

	hasBookkeepingExportAccess() {
		return this.Bookkeeping.Export;
	}

	hasLoneuppdragReadAccess() {
		return this.Loneuppdrag.Read;
	}

	hasLoneuppdragWriteAccess({HasPlus}) {
		return this.Loneuppdrag.Write && HasPlus;
	}

	hasFKSjukanmalanAccess() {
		return this.FKSjukanmalan.Read;
	}

	hasDataTreeSearchAccess() {
		return this.DataTreeSearch.Read;
	}

	hasSemBerReadAccess({HasPlus}) {
		return this.Semesterberedning.Read && HasPlus;
	}

	hasFerLonReadAccess({HasUserOption}) {
		return HasUserOption.FERTIL && this.FerLon.Read;
	}

	hasFerLonWriteAccess({HasUserOption}) {
		return HasUserOption.FERTIL && this.FerLon.Write;
	}

	hasFerLonUpdAccess({HasUserOption}) {
		return HasUserOption.FERTIL && this.FerLon.Upd;
	}

	hasUphLonReadAccess({HasUserOption}) {
		return HasUserOption.FERTIL && this.UphLon.Read;
	}

	hasUphLonWriteAccess({HasUserOption}) {
		return HasUserOption.FERTIL && this.UphLon.Write;
	}

	hasUphLonUpdAccess({HasUserOption}) {
		return HasUserOption.FERTIL&& this.UphLon.Upd;
	}

	hasKUBerReadAccess({HasPlus}) {
		return this.Kontrolluppgift.Read && HasPlus;
	}
	hasKUBerWriteAccess({HasPlus}) {
		return this.Kontrolluppgift.Write && HasPlus;
	}
	hasSemBerWriteAccess({HasPlus}) {
		return this.Semesterberedning.Write && HasPlus;
	}

	hasSemBerUpdAccess({HasPlus}) {
		return this.Semesterberedning.Upd && HasPlus;
	}

	hasForaReadAccess({AmfTecknad}) {
		return this.Fora.Read && AmfTecknad;
	}

	hasForaWriteAccess({AmfTecknad}) {
		return this.Fora.Write && AmfTecknad;
	}

	hasForaExportAccess({AmfTecknad}) {
		return this.Fora.Export && AmfTecknad;
	}

	hasCollectumReadAccess({HasPlus, ITPTecknad}) {
		return (this.Collectum.ITP1.Read || this.Collectum.ITP2.Read) && ITPTecknad && HasPlus;
	}

	hasITP1ReadAccess({HasPlus, ITPTecknad}) {
		return this.Collectum.ITP1.Read && ITPTecknad && HasPlus;
	}

	hasITP1WriteAccess({HasPlus, ITPTecknad}) {
		return this.Collectum.ITP1.Write && ITPTecknad && HasPlus;
	}

	hasITP1ExportAccess({HasPlus, ITPTecknad}) {
		return this.Collectum.ITP1.Export && ITPTecknad && HasPlus;
	}

	hasITP2ReadAccess({HasPlus, ITPTecknad}) {
		return this.Collectum.ITP2.Read && ITPTecknad && HasPlus;
	}

	hasITP2WriteAccess({HasPlus, ITPTecknad}) {
		return this.Collectum.ITP2.Write && ITPTecknad && HasPlus;
	}

	hasITP2ExportAccess({HasPlus, ITPTecknad}) {
		return this.Collectum.ITP2.Export && ITPTecknad && HasPlus;
	}

	hasSCBReadAccess({HasPlus, ScbStatistik}) {
		return (
			this.SCB.SLP.Read ||
			this.SCB.KLP.Read ||
			this.SCB.KSJU.Read ||
			this.SCB.KSP.Read
		) && ScbStatistik && HasPlus;
	}

	hasSLPReadAccess({HasPlus, ScbStatistik}) {
		return this.SCB.SLP.Read && HasPlus && ScbStatistik;
	}

	hasSLPWriteAccess({HasPlus, ScbStatistik}) {
		return this.SCB.SLP.Write && HasPlus && ScbStatistik;
	}

	hasSLPExportAccess({HasPlus, ScbStatistik}) {
		return this.SCB.SLP.Export && HasPlus && ScbStatistik;
	}

	hasKLPReadAccess({HasPlus, ScbStatistik}) {
		return this.SCB.KLP.Read && HasPlus && ScbStatistik;
	}

	hasKLPWriteAccess({HasPlus, ScbStatistik}) {
		return this.SCB.KLP.Write && HasPlus && ScbStatistik;
	}

	hasKLPExportAccess({HasPlus, ScbStatistik}) {
		return this.SCB.KLP.Export && HasPlus && ScbStatistik;
	}

	hasKSJUReadAccess({HasPlus, ScbStatistik}) {
		return this.SCB.KSJU.Read && HasPlus && ScbStatistik;
	}

	hasKSJUWriteAccess({HasPlus, ScbStatistik}) {
		return this.SCB.KSJU.Write && HasPlus && ScbStatistik;
	}

	hasKSJUExportAccess({HasPlus, ScbStatistik}) {
		return this.SCB.KSJU.Export && HasPlus && ScbStatistik;
	}

	hasSUSReadAccess({HasPlus, ScbStatistik}) {
		return this.SCB.SUS.Read && HasPlus && ScbStatistik;
	}

	hasSUSWriteAccess({HasPlus, ScbStatistik}) {
		return this.SCB.SUS.Write && HasPlus && ScbStatistik;
	}

	hasSUSExportAccess({HasPlus, ScbStatistik}) {
		return this.SCB.SUS.Export && HasPlus && ScbStatistik;
	}
	hasKSPReadAccess({HasPlus, ScbStatistik}) {
		return this.SCB.KSP.Read && HasPlus && ScbStatistik;
	}

	hasKSPWriteAccess({HasPlus, ScbStatistik}) {
		return this.SCB.KSP.Write && HasPlus && ScbStatistik;
	}

	hasSAFReadAccess({HasPlus, SafMedlem}) {
		return this.SAF.Read && SafMedlem && HasPlus;
	}

	hasSAFWriteAccess({HasPlus, SafMedlem}) {
		return this.SAF.Write && SafMedlem && HasPlus;
	}

	hasSAFAPIAccess({HasPlus, SafMedlem}) {
		return this.SAF.API && SafMedlem && HasPlus;
	}

	hasSAFExportAccess({HasPlus, SafMedlem}) {
		return this.SAF.Export && SafMedlem && HasPlus;
	}

	hasKFOReadAccess({HasPlus, KfoMedlem}) {
		return this.KFO.Read && KfoMedlem && HasPlus;
	}

	hasKFOWriteAccess({HasPlus, KfoMedlem}) {
		return this.KFO.Write && KfoMedlem && HasPlus;
	}

	hasKFOExportAccess({HasPlus, KfoMedlem}) {
		return this.KFO.Export && KfoMedlem && HasPlus;
	}

	hasKPBerReadAccess({HasPlus, KPTecknad}) {
		return this.KP.Read && KPTecknad && HasPlus;
	}

	hasKPBerWriteAccess({HasPlus, KPTecknad}) {
		return this.KP.Write && KPTecknad && HasPlus;
	}

	hasKPBerExportAccess({HasPlus, KPTecknad}) {
		return this.KP.Export && KPTecknad && HasPlus;
	}

	hasLOSENReadAccess({HasPlus, UseLosen}) {
		return this.LOSEN.Read && UseLosen && HasPlus;
	}

	hasLOSENWriteAccess({HasPlus, UseLosen}) {
		return this.LOSEN.Write && UseLosen && HasPlus;
	}

	hasLOSENExportAccess({HasPlus, UseLosen}) {
		return this.LOSEN.Export && UseLosen && HasPlus;
	}

	hasForskUtbReadAccess({HasPlus}) {
		return this.ForskUtb.Read && HasPlus;
	}

	hasForskUtbWriteAccess({HasPlus}) {
		return this.ForskUtb.Write && HasPlus;
	}

	hasLoneRevisionReadAccess({HasPlus}) {
		return this.LoneRevision.Read && HasPlus;
	}

	hasLoneRevisionWriteAccess({HasPlus}) {
		return this.LoneRevision.Write && HasPlus;
	}

	hasLoneRevisionApproveAccess({HasPlus}) {
		return this.LoneRevision.Approve && HasPlus;
	}

	hasLoneRevisionAttestAccess({HasPlus}) {
		return this.LoneRevision.Attest && HasPlus;
	}

	hasLonekartlaggningAccess({HasPlus}) {
		return this.Lonekartlaggning.Read && HasPlus;
	}

	hasLonekartlaggningArbetsvarderingAccess({HasPlus}) {
		return this.Lonekartlaggning.Arbetsvardering && HasPlus;
	}

	hasRetroLonReadAccess({HasPlus}) {
		return this.RetroLon.Read && HasPlus;
	}

	hasRetroLonWriteAccess({HasPlus}) {
		return this.RetroLon.Write && HasPlus;
	}

	hasRetroLonApproveAccess({HasPlus}) {
		return this.RetroLon.Approve && HasPlus;
	}

	hasNarvarostatusAccess() {
		return this.Narvarostatus.Read;
	}

	hasDocumentsReadAccess({HasPlus}) {
		return this.Documents.Read && HasPlus;
	}

	hasDocumentsWriteAccess({HasPlus}) {
		return this.Documents.Write && HasPlus;
	}

	hasLSSRapOmkReadAccess({userLSSEnabled}) {
		return this.LSS.RapOmk.Read && userLSSEnabled;
	}

	hasLSSRapOmkWriteAccess({userLSSEnabled}) {
		return this.LSS.RapOmk.Write && userLSSEnabled;
	}

	hasLSSBerReadAccess({userLSSEnabled}) {
		return this.LSS.Ber.Read && userLSSEnabled;
	}

	hasLSSBerWriteAccess({userLSSEnabled}) {
		return this.LSS.Ber.Write && userLSSEnabled;
	}

	hasLSSRapErsReadAccess({userLSSEnabled}) {
		return this.LSS.RapErs.Read && userLSSEnabled;
	}

	hasLSSRapErsWriteAccess({userLSSEnabled}) {
		return this.LSS.RapErs.Write && userLSSEnabled;
	}

	hasLSSRedovReadAccess({userLSSEnabled}) {
		return this.LSS.Redov.Read && userLSSEnabled;
	}

	hasLSSAterrapporteringReadAccess({userLSSEnabled}) {
		return this.LSS.Aterrapportering.Read && userLSSEnabled;
	}

	hasLSSKostnTypReadAccess({userLSSEnabled}) {
		return this.LSS.KostnTyp.Read && userLSSEnabled;
	}

	hasLSSKostnTypWriteAccess({userLSSEnabled}) {
		return this.LSS.KostnTyp.Write && userLSSEnabled;
	}

	hasLSSSettingsReadAccess({userLSSEnabled}) {
		return this.LSS.Settings.Read && userLSSEnabled;
	}

	hasLSSSettingsWriteAccess({userLSSEnabled}) {
		return this.LSS.Settings.Write && userLSSEnabled;
	}

	hasDebKontoReportAccess({Roll}) {
		return this.Reports.DebKonto.Read && ['LIC', 'ADM'].includes(Roll);
	}

	hasBruttoloneListaReportAccess() {
		return this.Reports.BruttoloneLista.Read;
	}


	hasLoneartslistaReportAccess({HasPlus}) {
		return HasPlus && this.Reports.Loneartslista.Read;
	}
	hasResultatrapportReportAccess() {
		return this.Reports.Resultatrapport.Read;
	}

	hasLonestatistikReportAccess() {
		return this.Reports.Lonestatistik.Read;
	}

	hasSaldoskuldReportAccess({HasPlus}) {
		return this.Reports.Saldoskuld.Read && HasPlus;
	}

	hasLASListaReportAccess({HasPlus}) {
		return this.Reports.LASLista.Read && HasPlus;
	}

	hasSINKListaReportAccess({HasPlus}) {
		return this.Reports.SINKLista.Read && HasPlus;
	}

	hasPerListaReportAccess() {
		return this.Reports.PerLista.Read;
	}

	hasBrukarListaReportAccess({userLSSEnabled}) {
		return this.Reports.BrukarLista.Read && userLSSEnabled;
	}

	hasPerKontaktReportAccess() {
		return this.Reports.PerKontakt.Read;
	}

	hasPerRegReportAccess() {
		return this.Reports.PerReg.Read;
	}

	hasRehablistaReportAccess({HasPlus}) {
		return this.Reports.Rehablista.Read && HasPlus;
	}

	hasTimeJournalsReportAccess({HasPlus}) {
		return this.Reports.TimeJournals.Read && HasPlus;
	}
	hasLoneutmatningReportAccess() {
		return this.Reports.Loneutmatning.Read;
	}

	hasLoneutvecklingReportAccess({HasPlus}) {
		return this.Reports.Loneutveckling.Read && HasPlus;
	}

	hasFackAvgReportAccess({HasPlus}) {
		return this.Reports.FackAvg.Read && HasPlus;
	}

	hasPerRegReadAccess() {
		return this.Registry.PerReg.Read;
	}

	hasPerRegWriteAccess() {
		return this.Registry.PerReg.Write;
	}

	hasPerRegExportAccess() {
		return this.Registry.PerReg.Export;
	}

	hasPerRegArbGivIntygAccess() {
		return this.Registry.PerReg.ArbGivIntyg;
	}

	hasPerRegChangeAnstNrAccess() {
		return this.Registry.PerReg.ChangeAnstNr;
	}

	hasPerRegFranvaroAccess() {
		return this.Registry.PerReg.Franvaro;
	}

	hasCalcVehicleBenefitsAccess() {
		return this.Registry.PerReg.CalcVehicleBenefits;
	}

	hasPerRegistryCareAccess() {
		return this.Registry.PerReg.PerRegistryCare;
	}

	hasBrukarRegReadAccess({userLSSEnabled}) {
		return this.Registry.BrukarReg.Read && userLSSEnabled;
	}

	hasBrukarRegWriteAccess({userLSSEnabled}) {
		return this.Registry.BrukarReg.Write && userLSSEnabled;
	}

	hasBrukarRegChangeBrukareAccess() {
		return this.Registry.BrukarReg.ChangeBrukare;
	}

	hasBrukarAnsvReadAccess({userLSSEnabled}) {
		return this.Registry.BrukarReg.BrukarAnsv.Read && userLSSEnabled;
	}

	hasBrukarAnsvWriteAccess({userLSSEnabled}) {
		return this.Registry.BrukarReg.BrukarAnsv.Write && userLSSEnabled;
	}

	hasArbSchemaReadAccess() {
		return this.Registry.ArbSchema.Read;
	}

	hasArbSchemaWriteAccess() {
		return this.Registry.ArbSchema.Write;
	}

	hasDagSchemaReadAccess({HasPlus}) {
		return this.hasArbSchemaReadAccess() && HasPlus;
	}

	hasDagSchemaWriteAccess({HasPlus}) {
		return this.hasArbSchemaWriteAccess() && HasPlus;
	}

	hasOBReglerReadAccess({HasPlus}) {
		return this.Registry.OBRegler.Read && HasPlus;
	}

	hasOBReglerWriteAccess({HasPlus}) {
		return this.Registry.OBRegler.Write && HasPlus;
	}

	hasJorReglerReadAccess({HasPlus}) {
		return this.Registry.JorRegler.Read && HasPlus;
	}

	hasJorReglerWriteAccess({HasPlus}) {
		return this.Registry.JorRegler.Write && HasPlus;
	}

	hasBerReglerReadAccess({HasPlus}) {
		return this.Registry.BerRegler.Read && HasPlus;
	}

	hasBerReglerWriteAccess({HasPlus}) {
		return this.Registry.BerRegler.Write && HasPlus;
	}

	hasEldReglerReadAccess({HasPlus}) {
		return this.Registry.EldRegler.Read && HasPlus;
	}

	hasEldReglerWriteAccess({HasPlus}) {
		return this.Registry.EldRegler.Write && HasPlus;
	}

	hasHlgReglerReadAccess({HasPlus}) {
		return this.Registry.HlgRegler.Read && HasPlus;
	}

	hasHlgReglerWriteAccess({HasPlus}) {
		return this.Registry.HlgRegler.Write && HasPlus;
	}

	hasOvtReglerReadAccess({HasPlus}) {
		return this.Registry.OvtRegler.Read && HasPlus;
	}

	hasOvtReglerWriteAccess({HasPlus}) {
		return this.Registry.OvtRegler.Write && HasPlus;
	}

	hasAgreementsReadAccess() {
		return this.Registry.Agreements.Read;
	}

	hasAgreementsWriteAccess() {
		return this.Registry.Agreements.Write;
	}

	hasVacationAgreementsReadAccess({HasPlus}) {
		return HasPlus && this.Registry.VacationAgreements.Read;
	}

	hasVacationAgreementsWriteAccess() {
		return this.Registry.VacationAgreements.Write;
	}

	hasArtRegReadAccess() {
		return this.Registry.ArtReg.Read;
	}

	hasArtRegWriteAccess() {
		return this.Registry.ArtReg.Write;
	}

	hasCustomFormelVariablesReadAccess() {
		return this.Registry.CustomFormelVariables.Read;
	}

	hasCustomFormelVariablesWriteAccess() {
		return this.Registry.CustomFormelVariables.Write;
	}

	hasLonKoderReadAccess() {
		return this.Registry.LonKoder.Read;
	}

	hasLonKoderWriteAccess() {
		return this.Registry.LonKoder.Write;
	}

	hasTidKoderReadAccess() {
		return this.Registry.TidKoder.Read;
	}

	hasTidKoderWriteAccess() {
		return this.Registry.TidKoder.Write;
	}

	hasImpRegReadAccess() {
		return this.Registry.ImpReg.Read;
	}

	hasImpRegWriteAccess() {
		return this.Registry.ImpReg.Write;
	}

	hasKontoRegReadAccess() {
		return this.Registry.KontoReg.Read;
	}

	hasKontoRegWriteAccess() {
		return this.Registry.KontoReg.Write;
	}

	hasResEnhReadAccess({
		OpenResEnhet1,
		OpenResEnhet2,
		OpenResEnhet3,
		OpenResEnhet4,
		OpenResEnhet5,
		OpenResEnhet6,
		RegisterResEnh1,
		RegisterResEnh2,
		RegisterResEnh3,
		RegisterResEnh4,
		RegisterResEnh5,
		RegisterResEnh6,
	}) {
		return this.Registry.ResEnhet.Read && (
			(OpenResEnhet1 && RegisterResEnh1) ||
			(OpenResEnhet2 && RegisterResEnh2) ||
			(OpenResEnhet3 && RegisterResEnh3) ||
			(OpenResEnhet4 && RegisterResEnh4) ||
			(OpenResEnhet5 && RegisterResEnh5) ||
			(OpenResEnhet6 && RegisterResEnh6)
		);
	}

	hasResEnhWriteAccess({
		OpenResEnhet1,
		OpenResEnhet2,
		OpenResEnhet3,
		OpenResEnhet4,
		OpenResEnhet5,
		OpenResEnhet6,
		RegisterResEnh1,
		RegisterResEnh2,
		RegisterResEnh3,
		RegisterResEnh4,
		RegisterResEnh5,
		RegisterResEnh6,
	}) {
		return this.Registry.ResEnhet.Write && (
			(OpenResEnhet1 && RegisterResEnh1) ||
			(OpenResEnhet2 && RegisterResEnh2) ||
			(OpenResEnhet3 && RegisterResEnh3) ||
			(OpenResEnhet4 && RegisterResEnh4) ||
			(OpenResEnhet5 && RegisterResEnh5) ||
			(OpenResEnhet6 && RegisterResEnh6)
		);
	}

	hasFordelningsnycklarReadAccess({HasPlus}) {
		return this.Registry.Fordelningsnycklar.Read && HasPlus;
	}

	hasFordelningsnycklarWriteAccess({HasPlus}) {
		return this.Registry.Fordelningsnycklar.Write && HasPlus;
	}

	hasFriaAckarDefReadAccess({HasPlus}) {
		return this.Registry.FriaAckarDef.Read && HasPlus;
	}

	hasFriaAckarDefWriteAccess({HasPlus}) {
		return this.Registry.FriaAckarDef.Write && HasPlus;
	}

	hasEgenskaperReadAccess({HasPlus}) {
		return this.Registry.Egenskaper.Read && HasPlus;
	}

	hasEgenskaperWriteAccess({HasPlus}) {
		return this.Registry.Egenskaper.Write && HasPlus;
	}

	hasVarugrupperReadAccess() {
		return this.Registry.Varugrupper.Read;
	}

	hasVarugrupperWriteAccess() {
		return this.Registry.Varugrupper.Write;
	}

	hasDepartmentsReadAccess({UseAvdelning}) {
		return this.Registry.Departments.Read && UseAvdelning;
	}

	hasDepartmentsWriteAccess({UseAvdelning}) {
		return this.Registry.Departments.Write && UseAvdelning;
	}

	hasFackForReadAccess({HasPlus}) {
		return this.Registry.FackFor.Read && HasPlus;
	}

	hasFackForWriteAccess({HasPlus}) {
		return this.Registry.FackFor.Write && HasPlus;
	}

	hasHolidaysReadAccess() {
		return this.Registry.Holidays.Read;
	}

	hasHolidaysWriteAccess() {
		return this.Registry.Holidays.Write;
	}

	hasSalaryPeriodsReadAccess() {
		return this.Registry.SalaryPeriods.Read;
	}

	hasSalaryPeriodsWriteAccess() {
		return this.Registry.SalaryPeriods.Write;
	}

	hasCompanySettingsReadAccess() {
		return this.Settings.Company.Read;
	}

	hasCompanySettingsWriteAccess() {
		return this.Settings.Company.Write;
	}

	hasCompanySettingsDeleteAccess() {
		return this.Settings.Company.Delete;
	}

	hasBokforingSettingsReadAccess() {
		return this.Settings.Bokforing.Read;
	}

	hasBokforingSettingsWriteAccess() {
		return this.Settings.Bokforing.Write;
	}

	hasUsersReadAccess({Roll, FtgInfo}) {
		return this.Settings.Users.Read && (Roll === 'FTG' ? FtgInfo.UseFtgAdminUserAccountAccess : true);
	}

	hasUsersWriteAccess({Roll, FtgInfo}) {
		return this.Settings.Users.Write && (Roll === 'FTG' ? FtgInfo.UseFtgAdminUserAccountAccess : true);
	}

	hasUsersRightsGroupsReadAccess({Roll, HasPlus}) {
		return HasPlus && (this.Settings.UserRightsGroups.Read || Roll === 'LIC');
	}

	hasUsersRightsGroupsWriteAccess({Roll, HasPlus}) {
		return HasPlus && (this.Settings.UserRightsGroups.Write || Roll === 'LIC');
	}

	hasKortnummerReadAccess() {
		return this.Settings.Kortnummer.Read;
	}

	hasKortnummerWriteAccess() {
		return this.Settings.Kortnummer.Write;
	}

	hasIntegrationsReadAccess({Roll, FtgInfo}) {
		return this.Settings.Integrations.Read && (Roll === 'FTG' ? FtgInfo.UseFtgAdminIntegrationsAccess : true);
	}

	hasIntegrationsWriteAccess({Roll, FtgInfo}) {
		return this.Settings.Integrations.Write&& (Roll === 'FTG' ? FtgInfo.UseFtgAdminIntegrationsAccess : true);
	}

	hasSysDataReadAccess() {
		return this.Settings.SysData.Read;
	}

	hasDigitalMailboxSettingsReadAccess() {
		return this.Settings.DigitalMailbox.Read;
	}

	hasDigitalMailboxSettingsWriteAccess() {
		return this.Settings.DigitalMailbox.Write;
	}

	hasPrintoutsReadAccess() {
		return this.Settings.Printouts.Read;
	}

	hasPrintoutsWriteAccess() {
		return this.Settings.Printouts.Write;
	}

	hasExportSettingsReadAccess() {
		return this.Settings.Export.Read;
	}

	hasExportSettingsWriteAccess() {
		return this.Settings.Export.Write;
	}

	hasFTEHeadcountReportAccess({HasUserOption}) {
		return this.HR.FTEHeadcount.Read && HasUserOption.HRTIL;
	}
}

class LicUserRights extends UserRights {
	constructor() {
		super(...arguments);

		this.Id      = 'LICUSER';
		this.BenText = 'Licensanvändare';

		const setAccess = (target) => {
			for(const [key, value] of Object.entries(target)) {
				if('object' === typeof value) {
					setAccess(value);
				} else {
					target[key] = true;
				}
			}
		};

		for(const [, value] of Object.entries(this)) {
			if('object' === typeof value) {
				setAccess(value);
			}
		}
	}
}

class STDUserRights extends LicUserRights {
	constructor() {
		super(...arguments);

		this.Id      = 'STDUSER';
		this.BenText = 'Standardbehörighet';

		this.Settings.UserRightsGroups.Read = false;
		this.Settings.UserRightsGroups.Write = false;
	}
}

class STDReadOnlyUserRights extends UserRights {
	constructor() {
		super(...arguments);

		this.Id      = 'STDREAD';
		this.BenText = 'Läsbehörighet';

		const setReadAccess = (target) => {
			for(const [key, value] of Object.entries(target)) {
				if('object' === typeof value) {
					setReadAccess(value);
				} else if(key === 'Read' || key === 'LonDashboardStats') {
					target[key] = true;
				}
			}
		};

		for(const [, value] of Object.entries(this)) {
			if('object' === typeof value) {
				setReadAccess(value);
			}
		}
	}
}

class LonDemoUser extends STDUserRights {
	constructor() {
		super(...arguments);

		this.Id      = 'LONDEMO';
		this.BenText = 'Demoanvändare';

		this.Settings.DigitalMailbox.Write = false;
		this.EmployerDeclaration.Send      = false;
		this.Bookkeeping.Send              = false;
		this.SAF.API                       = false;
		this.Settings.Users.Read           = false;
		this.Settings.Users.Write          = false;
	}
}

class PendingUserRights extends UserRights {}

class WebLonHuvAttestUser extends UserRights {
	constructor() {
		super(...arguments);

		this.Id      = 'WEBLONHUVATTESTUSER';
		this.BenText = 'Attestant av lönebesked';

		this.Infopanel.LonDashboardStats = true;
		this.Payslips.Read               = true;
		this.Payslips.Attest             = true;
		this.Registry.PerReg.Read        = true;
		this.Kalendarie.Read             = true;
		this.Tidrapport.Read             = true;
		this.Ersattningar.Read           = true;
		this.Loneuppdrag.Read            = true;
	}
}

export {
	UserRights,
	LicUserRights,
	STDUserRights,
	STDReadOnlyUserRights,
	PendingUserRights,
	LonDemoUser,
	WebLonHuvAttestUser
};

/**
 * Created by simon on 2018-11-30.
 */

import {assign, has, isPlainObject, forEach, includes} from 'lodash';

class LocalStorage {
	constructor() {
		this.localStorageSupported = !!localStorage;

		this.localStorage = LocalStorage.defaults;

		if(this.localStorageSupported) {
			try {
				const fromLocalStorage = localStorage.getItem(LocalStorage.KEY);

				if(fromLocalStorage) {
					this.localStorage = assign(this.localStorage, JSON.parse(fromLocalStorage));
				}
			//eslint-disable-next-line
			} catch(error) {}
		}
	}

	static get KEY() {
		return 'DV-CRONA-LON-WEBB';
	}

	static get defaults() {
		return {
			Login: '',
		};
	}

	get(key, defaultValue) {
		if(!this.localStorageSupported) {
			return defaultValue;
		}

		return has(this.localStorage, key) ? this.localStorage[key] : defaultValue;
	}

	set(key, value) {
		if(!this.localStorageSupported) {
			return;
		}

		if(isPlainObject(key)) {
			forEach(key, (v, k) => this.set(k, v));
		} else {
			this.localStorage[key] = value;

			localStorage.setItem(LocalStorage.KEY, JSON.stringify(this.localStorage));
		}
	}

	setDarkModePreference(preference) {
		this.set('DARK_MODE_PREFERENCE', preference);
	}

	getDarkModePreference() {
		const value = this.get('DARK_MODE_PREFERENCE', null);

		if(value === null) {
			return !!(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
		}

		return value === true;
	}

	getFavouriteCompanies() {
		return this.get('FavouriteCompanies') || [];
	}

	addFavouriteCompany(FtgId) {
		if(this.isFavouriteCompany(FtgId)) {
			return false;
		}

		this.set('FavouriteCompanies', [...this.getFavouriteCompanies(), FtgId]);

		return true;
	}

	removeFavouriteCompany(FtgId) {
		if(!this.isFavouriteCompany(FtgId)) {
			return false;
		}

		this.set('FavouriteCompanies', this.getFavouriteCompanies().filter((v) => v !== FtgId));

		return true;
	}

	toggleFavouriteCompany(FtgId) {
		if(this.isFavouriteCompany(FtgId)) {
			this.removeFavouriteCompany(FtgId);

			return false;
		}

		this.addFavouriteCompany(FtgId);

		return true;
	}

	isFavouriteCompany(FtgId) {
		return includes(this.getFavouriteCompanies(), FtgId);
	}
}

export default new LocalStorage();

<template>
	<Dialog ref="dialog" title="Läs in kopia" :width="1200" persistent>
		<v-card flat>
			<v-card-text>
				<v-container fluid>
					<v-row dense>
						<v-col cols="12">
							<InfiniteSelect
									v-model="LicNrSelection"
									:loading="isLoadingLicNrSourceList"
									ref="LicNrSelection"
									name="LicNrSelection"
									:items="LicNrSourceList"
									item-value="LicNr"
									item-text="LicNr"
									label="Från licensnummer"
									placeholder="Välj licensnummer"
									@change="onLoadFilesList"
									clearable
							/>
						</v-col>

						<v-col cols="12">
							<v-card outlined>
								<v-card-text class="pa-0 ma-0">
									<v-simple-table dense fixed-header height="auto" style="max-height: 320px; overflow: auto;">
										<v-progress-linear indeterminate v-if="isLoadingFilesList"/>
										<thead>
										<tr>
											<th>Företagsnamn</th>
											<th>Företagsid</th>
											<th>Storlek</th>
											<th>Skickad av</th>
											<th>Skapad</th>
											<th></th>
										</tr>
										</thead>
										<tbody>
										<tr
												v-for="(item, index) in FilesList"
												:key="index"
												class="cursor-pointer"
												:class="{
															grey: selectedFileIndex === index,
															'lighten-2': selectedFileIndex === index && !$vuetify.theme.dark,
															'darken-1': selectedFileIndex === index&& $vuetify.theme.dark
														}"
												@click="selectedFileIndex = index"
										>
											<td>{{item.MetaData.CompanyName}}</td>
											<td>{{item.MetaData.FtgId}}</td>
											<td>{{prettyBytes(item.Size)}}</td>
											<td>{{item.MetaData.CreatedBy}}</td>
											<td>{{formatDateTime(item.Created)}}</td>
											<td class="text-right">
												<Tooltip :text="item.MetaData.InfoText" v-if="item.MetaData.InfoText && item.MetaData.InfoText.trim()">
													<v-icon class="cursor-pointer" color="accent">mdi-note-text</v-icon>
												</Tooltip>

												<Tooltip text="Ta bort">
													<v-btn color="accent" icon @click.stop.prevent="onDelete(index)"><v-icon>mdi-delete</v-icon></v-btn>
												</Tooltip>
											</td>
										</tr>
										</tbody>
									</v-simple-table>
								</v-card-text>
							</v-card>
						</v-col>

						<v-fade-transition hide-on-leave>
							<v-col cols="12" v-if="FilesList[selectedFileIndex] && FilesList[selectedFileIndex].MetaData && FilesList[selectedFileIndex].MetaData.History && FilesList[selectedFileIndex].MetaData.History.length > 0">
								<ToggleCard title="Historik" dense :visible="false">
									<v-card outlined>
										<v-card-text class="pa-0 ma-0">
											<v-simple-table dense fixed-header height="auto" style="max-height: 320px; overflow: auto;">
												<v-progress-linear indeterminate v-if="isLoadingFilesList"/>
												<thead>
												<tr>
													<th>Händelse</th>
													<th class="text-right">Tidpunkt</th>
												</tr>
												</thead>
												<tbody>
												<tr v-for="(message, index) in FilesList[selectedFileIndex].MetaData.History" :key="`History_${index}`">
													<td>{{message.split(']')[1]}}</td>
													<td class="text-right">{{message.split(']')[0].substr(1)}}</td>
												</tr>
												</tbody>
											</v-simple-table>
										</v-card-text>
									</v-card>
								</ToggleCard>
							</v-col>
						</v-fade-transition>

						<v-col cols="12" class="mb-5">
							<SimpleCheckbox ref="AcceptedDeletionTerms" v-model="AcceptedDeletionTerms" label="Jag förstår att inläst företag ska tas bort när jag inte längre behöver titta i kopian och det är jag själv som ska ta bort företaget från denna licens" dense hide-details/>
						</v-col>

						<v-col cols="4" v-if="currentSelectionFtgId">
							<SimpleTextInput label="Företagsid vid inläsning av kopia" ref="TargetSchemaName" v-model="TargetSchemaName" :counter="15" :placeholder="currentSelectionFtgId"/>
						</v-col>

						<v-col cols="8" v-if="currentSelectionFtgId">
							<SimpleTextInput label="Frivillig intern notering" ref="InfoText" v-model="InfoText" :counter="100" placeholder="Ingen" hint="Exempelvis referens till ärendenummer eller dylikt. Synlig vid val av företag." persistent-hint/>
						</v-col>

						<v-fade-transition hide-on-leave>
							<v-col cols="12" v-if="isRestoring" class="pt-5">
								{{statusMessage}}
								<v-progress-linear indeterminate height="15"/>
							</v-col>
						</v-fade-transition>

						<v-fade-transition hide-on-leave>
							<v-col cols="12" v-if="showLargeCompaniesText" class="pt-3">
								<v-alert border="top" colored-border type="info" elevation="1" class="ma-0 caption" dismissible dense>
									Tänk på att större företag kan ta längre tid att läsa in, vänligen avbryt inte när du väl påbörjat inläsning.
								</v-alert>
							</v-col>
						</v-fade-transition>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>

		<template v-slot:buttons="{hide}">
			<v-btn color="secondary" :disabled="isRestoring" @click="hide">Avbryt</v-btn>
			<v-spacer></v-spacer>
			<v-btn color="primary" ref="RestoreBtn" :loading="isRestoring" :disabled="!AcceptedDeletionTerms || selectedFileIndex === -1" @click="onRestore">{{selectedFileIndex === -1 ? 'Välj ett företag i listan' : 'Läs in valt företag'}}</v-btn>
		</template>
	</Dialog>
</template>

<script>
import moment                 from 'moment';
import prettyBytes            from 'pretty-bytes';
import {mapActions, mapState} from 'vuex';
import InfiniteSelect         from './InfiniteSelect.vue';
import Dialog                 from './Dialog.vue';
import Tooltip                from './Tooltip.vue';
import SimpleCheckbox         from './SimpleCheckbox.vue';
import ToggleCard             from './ToggleCard.vue';
import SimpleTextInput        from './SimpleTextInput.vue';

export default {
	name: 'CompanyRestoreDialog',

	data: () => ({
		LicNrSelection:           '',
		LicNrSourceList:          [],
		isLoadingLicNrSourceList: true,
		FilesList:                [],
		FilesListSelection:       '',
		isLoadingFilesList:       false,
		selectedFileIndex:        -1,
		isRestoring:              false,
		AcceptedDeletionTerms:    false,
		TargetSchemaName:         '',
		InfoText:                 '',
		statusMessage:            '',
		tabOrder:                 [
			'LicNrSelection',
			'AcceptedDeletionTerms',
			'TargetSchemaName',
			'InfoText',
			'RestoreBtn',
		],
	}),

	computed: {
		...mapState(['UserAccount']),

		currentSelectionFtgId() {
			if(this.selectedFileIndex >= 0) {
				return this.FilesList[this.selectedFileIndex].MetaData.FtgId;
			}

			return '';
		},

		showLargeCompaniesText() {
			return this.selectedFileIndex !== -1 && this.FilesList[this.selectedFileIndex].Size > 37_500_000;
		},
	},

	methods: {
		...mapActions(['confirmDialog']),

		onDelete(index) {
			const Container = this.LicNrSelection;
			const File      = this.FilesList[index];

			this.confirmDialog({
				title:        'Ta bort kopia',
				text:         `Är du säker på att du vill ta bort kopian <b>${this.$formatter.escape(File.MetaData.CompanyName)}</b> från <b>${this.$formatter.escape(Container)}</b> som skickades in av <b>${this.$formatter.escape(File.MetaData.CreatedBy)}</b>?`,
				cancelText:   'Avbryt',
				confirmText:  'Ta bort',
				confirmColor: 'error',
				onConfirm:    () => {
					this.$tryCatch({
						task: async () => {
							await this.$http.post('/restoreCompany/deleteCopy', {
								Container: File.Container,
								Name:      File.Name,
								VersionId: File.Version,
							});

							this.$msgSuccess('Kopian har tagits bort.');

							this.FilesList.splice(index, 1);
							this.selectedFileIndex = -1;
						},
					});
				},
			});
		},

		show() {
			this.statusMessage = '';
			this.AcceptedDeletionTerms = false;
			this.selectedFileIndex = -1;
			this.LicNrSelection = '';
			this.FilesList = [];
			this.loadLicNrSourceList();
			this.$refs.dialog.show();
		},

		formatDateTime(value) {
			return moment(value).format('YYYY-MM-DD HH:mm');
		},

		prettyBytes(value) {
			return prettyBytes(value);
		},

		onLoadFilesList() {
			this.selectedFileIndex = -1;

			this.$tryCatch({
				task: async () => {
					this.isLoadingFilesList = true;

					if(!this.LicNrSelection) {
						this.FilesList = [];
						
						return;
					}

					this.FilesList = (await this.$http.get(`/restoreCompany/listFiles/${encodeURIComponent(this.LicNrSelection)}`)).filter(({MetaData}) => MetaData && MetaData.FtgId);
				},

				finally: () => {
					this.isLoadingFilesList = false;
				},
			});
		},

		loadLicNrSourceList() {
			this.$tryCatch({
				task: async () => {
					this.isLoadingLicNrSourceList = true;

					this.LicNrSourceList = await this.$http.get('/restoreCompany/listSources');
				},

				finally: () => {
					this.isLoadingLicNrSourceList = false;
				},
			});
		},

		onRestore() {
			this.TargetSchemaName = this.TargetSchemaName.trim().toLowerCase();

			if(this.TargetSchemaName && (this.TargetSchemaName.replace(/[\da-z]/gi, '') !== '' || this.TargetSchemaName.length > 15)) {
				this.$msgError('Företagsid får endast bestå av A-Z, 0-9, och vara max 15 tecken långt.');

				return;
			}

			const Container = this.LicNrSelection;
			const File = this.FilesList[this.selectedFileIndex];

			this.confirmDialog({
				title:       'Läs in kopia',
				text:        `Är du säker på att du vill läsa in kopian av <b>${this.$formatter.escape(File.MetaData.CompanyName)}</b> från <b>${Container}</b> till din licens <b>${this.$formatter.escape(this.UserAccount.LicNr)}</b>?`,
				cancelText:  'Avbryt',
				confirmText: 'Läs in',
				onConfirm:   () => {
					this.$tryCatch({
						task: async () => {
							this.isRestoring = true;

							const TargetSchemaName = this.TargetSchemaName || File.MetaData.FtgId;

							const {Id} = await this.$http.post('/restoreCompany', {
								Container: File.Container,
								Name:      File.Name,
								VersionId: File.Version,
								FtgId:     File.MetaData.FtgId,
								TargetSchemaName,
								InfoText:  this.InfoText,
							});


							await new Promise((resolve, reject) => {
								const fetchStatus = async () => {
									const data = await this.$http.get(`/restoreCompany/status/${encodeURIComponent(Id)}`);

									if(!data || data.Status === 'FAILED') {
										reject(new Error('Job Failed'));

										return;
									}

									switch (data.Status) {
										case 'COMPLETED': {
											this.$msgSuccess('Kopian har lästs in.');
											this.$emit('restored', TargetSchemaName);
											this.$refs.dialog.hide();
											resolve();

											break;
										}
										case 'INPROGRESS': {
											this.statusMessage = 'Kopian läses in, vänligen vänta...';
											setTimeout(fetchStatus, 5000);

											break;
										}
										case 'QUEUED': {
											this.statusMessage = 'Din begäran ligger i kö, vänligen vänta...';
											setTimeout(fetchStatus, 2500);

											break;
										}
									}
								};

								fetchStatus();
							});
						},

						finally: () => {
							this.isRestoring = false;
						},
					});
				},
			});
		},
	},

	components: {SimpleTextInput, ToggleCard, SimpleCheckbox, Tooltip, Dialog, InfiniteSelect},
}
</script>

<style scoped>

</style>

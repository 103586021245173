/**
 * Created by simon on 2018-12-27.
 */

export const LSSBerPerInt = [
	{value: '001', text: '1 månad'},
	{value: '002', text: '2 månader'},
	{value: '003', text: '3 månader'},
	{value: '004', text: '4 månader'},
	{value: '005', text: '5 månader'},
	{value: '006', text: '6 månader'},
	{value: '104', text: '4 veckor'},
];

export const PerTyp = [
	{value: 'ARB', text: 'Arbetare'},
	{value: 'TJM', text: 'Tjänsteman'},
	{value: 'LED', text: 'Företagsledning'},
	{value: 'DEL', text: 'Delägare'},
	{value: 'IDR', text: 'Idrottsutövare'},
	{value: 'ÖVR', text: 'Annan'},
];

export const AnstForm = [
	{value: 'TV', text: 'Tillsvidareanställning'},
	{value: 'PRO', text: 'Provanställning'},
	{value: 'TID', text: 'Visstidsanställning'},
	{value: 'VIK', text: 'Vikariat'},
	{value: 'PEN', text: 'Efter pensionering'},
	{value: 'PRJ', text: 'Projektanställning'},
	{value: 'PRA', text: 'Praktikarbete'},
	{value: 'FER', text: 'Feriearbete'},
	{value: 'SES', text: 'Säsongsanställd'},
	{value: 'AVT', text: 'Särskilt avtal'},
	{value: 'NEJ', text: 'Ej anställd'},
];

export const LoneForm = [
	{value: 'MÅN', text: 'Månadslön (Fast lön)'},
	{value: 'TIM', text: 'Timlön (Rörlig lön)'},
];

export const KalReadyTyp = [
	{value: 'DAG', text: 'Hela dagar'},
	{value: 'TID', text: 'Endast tider'},
];

export const KalAutoSchematid = [
	{value: 'NULL', text: 'Om schema saknas helt på dagen'},
	{value: 'NOLL', text: 'Vid registrering av tid på ledig dag'},
	{value: 'FILL', text: 'Om registrerad tid är mer än schematiden'},
];

export const StartBokfAr = [
	{text: 'Januari', value: '01'},
	{text: 'Februari', value: '02'},
	{text: 'Mars', value: '03'},
	{text: 'April', value: '04'},
	{text: 'Maj', value: '05'},
	{text: 'Juni', value: '06'},
	{text: 'Juli', value: '07'},
	{text: 'Augusti', value: '08'},
	{text: 'September', value: '09'},
	{text: 'Oktober', value: '10'},
	{text: 'November', value: '11'},
	{text: 'December', value: '12'},
];

export const MonthList = [
	{text: 'Januari', value: 1},
	{text: 'Februari', value: 2},
	{text: 'Mars', value: 3},
	{text: 'April', value: 4},
	{text: 'Maj', value: 5},
	{text: 'Juni', value: 6},
	{text: 'Juli', value: 7},
	{text: 'Augusti', value: 8},
	{text: 'September', value: 9},
	{text: 'Oktober', value: 10},
	{text: 'November', value: 11},
	{text: 'December', value: 12},
];

export const KalLss = [
	{value: 'NEJ', text: 'Ingen'},
	{value: 'TID', text: 'Övrig tid'},
	{value: 'ASS', text: 'Assistanstid'},
	{value: 'JOR', text: 'Jourtid'},
	{value: 'BER', text: 'Beredskapstid'},
	{value: 'FRA', text: 'Frånvarotid'},
];

export const KalObe = [
	{value: 'NEJ', text: 'Ingen'},
	{value: 'OVT', text: 'Övertid'},
	{value: 'NAR', text: 'Närvarotid'},
	{value: 'SCH', text: 'Schematid'},
	{value: 'ARB', text: 'Arbetstid'},
	{value: 'SJK', text: 'Sjukfrånvaro'},
	{value: 'FRA', text: 'Frånvarotid'},
];

export const DagTyp = [
	{value: '--', text: 'Ingen avvikelse'},
	{value: '10', text: 'Vardag'},
	{value: '16', text: 'Lördag'},
	{value: '17', text: 'Söndag'},
	{value: 'HA', text: 'Helgafton'},
	{value: 'HD', text: 'Helgdag'},
	{value: 'HK', text: 'Klämdag helg'},
	{value: 'SA', text: 'Storhelgsafton'},
	{value: 'SD', text: 'Storhelgsdag'},
	{value: 'SK', text: 'Klämdag storhelg'},
	{value: 'YA', text: 'Nyårsafton'},
	{value: 'YD', text: 'Nyårsdagen'},
	{value: 'TA', text: 'Trettondagsafton'},
	{value: 'TD', text: 'Trettondagen'},
	{value: 'PT', text: 'Skärtorsdagen'},
	{value: 'PF', text: 'Långfredagen'},
	{value: 'PA', text: 'Påskafton'},
	{value: 'PD', text: 'Påskdagen'},
	{value: 'PE', text: 'Annandag påsk'},
	{value: 'FA', text: 'Valborgsafton'},
	{value: 'FD', text: 'Första maj'},
	{value: 'KD', text: 'Kristi himmelsfärdsdag'},
	{value: 'IA', text: 'Pingstafton'},
	{value: 'ID', text: 'Pingstdagen'},
	{value: 'ND', text: 'Nationaldagen'},
	{value: 'MA', text: 'Midsommarafton'},
	{value: 'MD', text: 'Midsommardagen'},
	{value: 'AD', text: 'Alla helgons dag'},
	{value: 'JA', text: 'Julafton'},
	{value: 'JD', text: 'Juldagen'},
	{value: 'JE', text: 'Annandag jul'},
];

export const UtbetPer = [
	{value: 'M', text: 'Månadslön, innevarande'},
	{value: 'K', text: 'Månadslön, föregående'},
	{value: 'V', text: 'Veckolön'},
	{value: 'W', text: 'Tvåveckorslön'},
	{value: 'Q', text: 'Fyraveckorslön'},
	{value: 'H', text: 'Halvmånadslön'},
];

export const PrelSkattTyp = [
	{value: 'HUV', text: 'Huvudarbetsgivare (enligt skattetabell)'},
	{value: 'EXT', text: 'Sidoinkomster (30%)'},
	{value: 'TMP', text: 'Korttidsarbete mindre än 1 vecka (30%)'},
	{value: 'STU', text: 'Studerande utan skatteavdrag (0%)'},
	{value: 'JUR', text: 'Juridisk person utan F-skatt (30%)'},
	{value: 'FTG', text: 'Uppdragstagare med F-skatt (0%)'},
	process.env.VUE_APP_ENABLE_ARBAVGUTS === 'true' ? {value: 'S25', text: 'Utomlands bosatta SINK (25%)'} : null,
	// process.env.VUE_APP_ENABLE_ARBAVGUTS === 'true' ? {value: 'S15', text: 'Utomlands bosatta sjömän SINK (15%)'} : null,
	{value: 'A15', text: 'A-SINK (15%)'},
	{value: 'LOK', text: 'Lokalanställd (0%)'},
	{value: '???', text: 'Okänt skatteförhållande (standardtabell + 10%)'},
	{value: 'NEJ', text: 'Inget skatteavdrag (0%)'},
].filter((v) => v);

export const Jamkning = [
	{value: '%', text: '% i skatt'},
	// {value: '+', text: 'kr ökat underlag'},
	// {value: '-', text: 'kr minskat underlag'},
	// {value: '0', text: 'ingen skatt'},
];

export const KlassTyp = [
	{value: 'BRU+', text: 'Bruttolön'},
	{value: 'BRU-', text: 'Bruttoavdrag'},
	{value: 'NAT+', text: 'Naturaförmån'},
	{value: 'KOA-', text: 'Kostnadsavdrag'},
	{value: 'TID-', text: 'Tidsavdrag'},
	{value: 'TID+', text: 'Tidslöneart'},
	{value: 'PAS+', text: 'Passiv'},
	{value: 'PAS-', text: 'Passiv avdrag'},
	{value: 'NET+', text: 'Nettoersättning'},
	{value: 'NET-', text: 'Nettoavdrag'},
	{value: 'SKT-', text: 'Frivillig skatt'},
];

export const SkatteTyp = [
	{value: 'SG', text: 'Skattegrundande'},
	{value: 'ES', text: 'Engångsskattegrundande'},
	{value: 'KS', text: 'Kapitalskattegrundande'},
	{value: 'SF', text: 'Skattefri'},
];

export const UtbetTyp = [
	{value: 'BNK', text: 'Bank kontoinsättning'},
	{value: 'BNU', text: 'Bank utbetalningskort'},
	{value: 'PGU', text: 'Plusgiro utbetalningslista'},
	{value: 'KON', text: 'Kontantlista'},
];

export const KontUppgift = [
	{value: '00', text: 'Redovisas ej'},
	{value: '11', text: 'Kontant bruttolön m.m (bruttoavdrag) (011)'},
	{value: '12', text: 'Skattepliktiga förmåner utom bil- och drivmedel (012)'},
	{value: '13', text: 'Skattepliktig bilförmån utom drivmedel (013)'},
	{value: '16', text: 'Antal km med milersättning vid bilförmån (016)'},
	{value: '17', text: 'Betalt för bilförmån (017)'},
	{value: '18', text: 'Drivmedel vid bilförmån (018)'},
	{value: '20', text: 'Kostnadsersättning som inte kryssats vid kod 050-057 (020)'},
	{value: '21', text: 'Underlag för skattereduktion för hushållsarbete (021)'},
	{value: '22', text: 'Underlag för skattereduktion för ROT-arbete (022)'},
	{value: '23', text: 'Andel styrelsearvode (023)'},
	{value: '25', text: 'Skattepliktig ersättning grundande för egenavgifter (025)'},
	{value: '30', text: 'Tjänstepension (030)'},
	{value: '31', text: 'Skattepliktiga ersättningar, ej socialavgifter men jobbavdrag (031)'},
	{value: '32', text: 'Skattepliktiga ersättningar, ej socialavgifter, ej jobbavdrag (032)'},
	{value: '35', text: 'Vissa inte skattepl. ersättning till utländska forskare (035)'},
	{value: '36', text: 'Ersättningar/förmåner för bostad och resa med SINK skatt (036)'},
	{value: '37', text: 'Vissa avdrag (037)'},
	{value: '39', text: 'Hyresersättning (039)'},
	{value: '41', text: 'Förmån, Bostad småhus (041)'},
	{value: '42', text: 'Förmån, Kost (042)'},
	{value: '43', text: 'Förmån, Bostad ej småhus (043)'},
	{value: '44', text: 'Förmån, Ränta (044)'},
	{value: '45', text: 'Förmån, Parkering (045)'},
	{value: '47', text: 'Förmån, Annan förmån (047)'},
	{value: '48', text: 'Förmån, har justerats (048)'},
	{value: '49', text: 'Förmån, som pension (049)'},
	{value: '50', text: 'Bilersättning enligt schablon (050)'},
	{value: '51', text: 'Traktamente inrikes enligt schablon (051)'},
	{value: '52', text: 'Traktamente utrikes enligt schablon (052)'},
	{value: '53', text: 'Inrikes tjänsteresa längre tid än tre månader (053)'},
	{value: '54', text: 'Utrikes tjänsteresa längre tid än tre månader (054)'},
	{value: '55', text: 'Kostnadsersättning Reskostnader (055)'},
	{value: '56', text: 'Kostnadsersättning Logi (056)'},
	{value: '98', text: 'Betalat för drivmedel vid bilförmån (098)'},
	{value: '105', text: 'Dagpenning vid tjänstgöring inom totalförsvaret (005 / 004)'},
	{value: '131', text: 'Kontant ersättning, fri från avgift (131)'},
	{value: '132', text: 'Förmåner utom bilförmån, fri från avgift (132)'},
	{value: '133', text: 'Bilförmån, fri från avgift (133)'},
	{value: '503', text: 'Ränteinkomst, ej konto (503 blankett KU20)'},
	{value: '540', text: 'Betald och för året avdragsgill ränta (540 blankett KU25)'},
	{value: '541', text: 'Förskottsränta som ingår i totalt inbetald ränta (541 blankett KU25)'},
];

export const CoinsLonTyp = [
	{value: '000', text: 'Ej med'},
	{value: '029', text: '3-års snitt'},
	{value: '030', text: 'Månatliga tillägg - semestergrundande'},
	{value: '031', text: 'Månatliga tillägg - ej semestergrundande'},
	{value: '032', text: 'Årliga tillägg -  semestergrundande'},
	{value: '033', text: 'Årliga tillägg -  ej semestergrundande'},
	{value: '051', text: 'Jour/OB och beredskap'},
	{value: '052', text: 'Bonus; successivt 3-årssnitt'},
	{value: '053', text: 'Löneavstående belopp'},
	{value: '054', text: 'Övriga bruttoavdrag 1'},
	{value: '055', text: 'Övriga bruttoavdrag 2'},
	{value: '056', text: 'Övriga bruttoavdrag 3'},
	{value: '023', text: 'Frivillig grupplivförsäkring'},
	{value: '024', text: 'Tjänstledighet p.g.a. studier'},
	{value: '025', text: 'Tjänstledighet p.g.a. föräldraledighet'},
	{value: '026', text: 'Tjänstledighet p.g.a. militär tjänstgöring'},
	{value: '027', text: 'Tjänstledighet övrigt'},
	{value: '028', text: 'Sjukfrånvaro'},
];

export const LonStatistik = [
	{value: 'ARB', text: 'Arbetstid'},
	{value: 'ARBNOR', text: 'Arbetstid - Normal'},
	{value: 'ARBMER', text: 'Arbetstid - Mertid'},
	{value: 'ARBOVT', text: 'Arbetstid - Övertid'},
	{value: 'ARBRES', text: 'Arbetstid - Restid'},
	{value: 'ARBJOR', text: 'Arbetstid - Jourtid'},
	{value: 'ARBBER', text: 'Arbetstid - Beredskap'},
	{value: 'ARBTIL', text: 'Arbetstid - Lönetillägg'},
	{value: 'LON', text: 'Övrig lön'},
	{value: 'LONRET', text: 'Övrig lön - Retroaktiv'},
	{value: 'LONBON', text: 'Övrig lön - Bonus'},
	{value: 'LONÖVR', text: 'Övrig lön - Övrigt'},
	{value: 'FRM', text: 'Naturaförmån'},
	{value: 'FRMBIL', text: 'Naturaförmån - Bil'},
	{value: 'FRMMAT', text: 'Naturaförmån - Måltider'},
	{value: 'FRMHUS', text: 'Naturaförmån - Bostad'},
	{value: 'FRMRTA', text: 'Naturaförmån - Ränta'},
	{value: 'FRMÖVR', text: 'Naturaförmån - Övrigt'},
	{value: 'RES', text: 'Reseersättning'},
	{value: 'RESTRA', text: 'Reseersättning - Traktamenten'},
	{value: 'RESMAT', text: 'Reseersättning - Måltidsreduktion'},
	{value: 'RESMIL', text: 'Reseersättning - Milersättning'},
	{value: 'RESUTL', text: 'Reseersättning - Utlägg'},
	{value: 'NET', text: 'Nettolön'},
	{value: 'NETFOR', text: 'Nettolön - Förskott'},
	{value: 'NETAVD', text: 'Nettolön - Avdrag'},
	{value: 'NETFAK', text: 'Nettolön - Fackavgift'},
	{value: 'NETGRA', text: 'Nettolön - Granskningsavgift'},
	{value: 'NETFÖR', text: 'Nettolön - Gruppförsäkring'},
	{value: 'NETUTL', text: 'Nettolön - Utlägg'},
	{value: 'SJK', text: 'Sjukfrånvaro'},
	{value: 'SJKKAR', text: 'Sjukfrånvaro - Karens'},
	{value: 'SJKLON', text: 'Sjukfrånvaro - Sjuklön'},
	{value: 'SJKERS', text: 'Sjukfrånvaro - Sjukersättning'},
	{value: 'SJKPEN', text: 'Sjukfrånvaro - Sjukpenning'},
	{value: 'SJKTIL', text: 'Sjukfrånvaro - Lönetillägg'},
	{value: 'FRA', text: 'Frånvaro'},
	{value: 'FRAHAV', text: 'Frånvaro - Havandeskapspenning'},
	{value: 'FRAFPE', text: 'Frånvaro - Föräldrapenning'},
	{value: 'FRATFP', text: 'Frånvaro - Tillfällig föräldrapenning'},
	{value: 'FRAUTB', text: 'Frånvaro - Utbildning'},
	{value: 'FRAMIL', text: 'Frånvaro - Militärtjänst'},
	{value: 'FRATJT', text: 'Frånvaro - Tjänstledig'},
	{value: 'FRAÖVR', text: 'Frånvaro - Övrigt'},
	{value: 'SEM', text: 'Semester'},
	{value: 'SEMBET', text: 'Semester - Betald'},
	{value: 'SEMFOR', text: 'Semester - Förskott'},
	{value: 'SEMOBE', text: 'Semester - Obetald'},
	{value: 'SEMSPA', text: 'Semester - Sparad'},
	{value: 'SEMLÖN', text: 'Semester - Semesterlön'},
	{value: 'SEMERS', text: 'Semester - Semesterersättning'},
	{value: 'SEMFER', text: 'Semester - Ferielön'},
	{value: 'DIV', text: 'Diverse övrigt'},
];

export const SAFstatistik = [
	{value: '000', text: 'Ej med i SN statistiken'},
	{value: '051', text: 'Fast lön (10)'},
	{value: '058', text: 'Prestationslön (12)'},
	{value: '601', text: 'Summa tid-/prestationslön (13)'},
	{value: '052', text: 'Övertidstillägg (15a)'},
	{value: '053', text: 'Övertidsersättning (15b)'},
	{value: '054', text: 'OB-tillägg (16)'},
	{value: '056', text: 'Tillägg för speciella arbetsförhållanden (17)'},
	{value: '057', text: 'Andra förmåner (18)'},
	{value: '055', text: 'Andra ersättningar (18)'},
	{value: '800', text: 'Skiftformstillägg (19) (Stål- o Metallförbundet)'},
	{value: '801', text: 'Post- och ställningersättning (19) (Sågverksindustrin)'},
	// {value: '802', text: 'Kalvningstillägg (19) (Lantarbetare)'},
	{value: '816', text: 'Garantilön (19) (Bemanningsföretagen)'},
	{value: '817', text: 'Utbokad tid (21) (Bemanningsföretagen)'},
	{value: '805', text: 'Traktamente (21) (Sveriges Byggindustrier)'},
	{value: '806', text: 'Reskostnadsersättning (22) (Sveriges byggindustrier)'},
	{value: '808', text: 'Förrättningstillägg (23) (Sveriges Byggindustrier)'},
	{value: '813', text: 'Enkel OB (Biltrafikens arbetsgivarförbund)'},
	{value: '814', text: 'Kvalificerat OB (Biltrafikens arbetsgivarförbund)'},
	{value: '815', text: 'Skifttillägg (Biltrafiekns arbetsgivarförbund)'},
];

export const KFOstatistik = [
	{value: '000', text: 'Ej med i Fremia lönestatistik'},
	{value: '051', text: 'Överenskommen fast månads-, vecko-, eller timlön'},
	{value: '052', text: 'Tillägg vid mer- och övertid'},
	{value: '053', text: 'Ersättning vid mer- och övertid'},
	{value: '058', text: 'Rörlig lön'},
	{value: '601', text: 'Summa tid-/prestationslön'},
	{value: '054', text: 'Tillägg vid skift, OB, förskjuten arbetstid'},
	{value: '056', text: 'Tillägg för risk, smuts, värme m.m.'},
	{value: '055', text: 'Jour-, beredskaps-, vänte-, restidsersättning'},
	{value: '057', text: 'Därav förmåner'},
];

export const SCBStatistik = [
	{value: '000', text: 'Ej med i SCB lönestrukturstatistiken'},
	{value: '051', text: 'Överenskommen fast månads-, vecko-, eller timlön (6)'},
	{value: '052', text: 'Tillägg vid mer- och övertid (10b)'},
	{value: '053', text: 'Ersättning vid mer- och övertid (10c)'},
	{value: '058', text: 'Rörlig lön (11)'},
	{value: '054', text: 'Tillägg vid skift, OB, förskjuten arbetstid (12)'},
	{value: '056', text: 'Tillägg för risk, smuts, värme m.m. (12)'},
	{value: '055', text: 'Jour-, beredskaps-, vänte-, restidsersättning (13)'},
	{value: '057', text: 'Därav förmåner (14) (ingår i 13)'},
];

export const SCBKonjunktur = [
	{value: '--', text: 'Ej med i SCB konjunkturstatistiken'},
	{value: '00', text: 'Endast grundande arbetad tid'},
	{value: '02', text: 'Överenskomma fasta tillägg (2)'},
	{value: '04', text: 'Summa rörliga tillägg exkl. jourersättning och naturaförmån (4)'},
	{value: '05', text: 'Därav övertidsttillägg (5) (ingår i rad 4)'},
	{value: '06', text: 'Utbetald lön (för arbetad tid) före skatteavdrag (6)'},
	{value: '07', text: 'Därav övertidstillägg (7) (ingår i rad 6)'},
	{value: '46', text: 'Rörligt tillägg (4) eller utbetald lön (6)'},
	{value: '57', text: 'Därav övertidstillägg (5 eller 7) (ingår i rad 4 eller 6)'},
	{value: '10', text: 'Retroaktiv lön utbetald under perioden (10)'},
	{value: '12', text: 'Rörligt tillägg avseende tidigare perioder (12)'},
	{value: '13', text: 'Sjuklön (13)'},
];

export const AntalEnhet = [
	{value: 'tim', text: 'timmar'},
	{value: 'mån', text: 'månad'},
	{value: 'dgr', text: 'arbetsdagar'},
	{value: 'vec', text: 'veckor'},
	{value: 'km', text: 'kilometer'},
	{value: 'mil', text: 'mil'},
	{value: 'st', text: 'stycken'},
	{value: 'min', text: 'minuter'},
	{value: 'kr', text: 'kronor'},
	{value: '%', text: 'procent'},
	{value: 'dag', text: 'kalenderdagar'},
];

export const SkrivExText = [
	{value: 'FL', text: 'Före löneart'},
	{value: 'EL', text: 'Efter löneart'},
	{value: 'IN', text: 'Intern notering'},
];

export const SemGrundTyp = [
	{value: 'A', text: 'Lön arbetad tid'},
	{value: 'R', text: 'Lön rörlig del'},
	{value: 'L', text: 'Lön övrigt'},
];

export const SemNarvTyp = [
	{value: 'ADD', text: 'Öka arbetad tid'},
	{value: 'SUB', text: 'Minska arbetad tid'},
];

export const SemFranTyp = [
	{value: 'SJK', text: 'Sjukfrånvaro'},
	{value: 'SMB', text: 'Smittbärare'},
	{value: 'ARB', text: 'Arbetsskada'},
	{value: 'REH', text: 'Rehabilitering'},
	{value: 'FLS', text: 'Föräldraledighet sammanboende'},
	{value: 'FLE', text: 'Föräldraledighet ensamstående'},
	{value: 'PAP', text: 'Pappaledig'},
	{value: 'KON', text: 'Kontaktdagar'},
	{value: 'HAV', text: 'Graviditetspenning'},
	{value: 'HAF', text: 'Graviditetspenning fosterskada'},
	{value: 'TFP', text: 'Tillfällig föräldrapeng'},
	{value: 'UTB', text: 'Utbildning med studiestöd'},
	{value: 'FAC', text: 'Facklig utbildning'},
	{value: 'MIL', text: 'Militär repitionsövning'},
	{value: 'SVE', text: 'Svenskundervisning för invandrare'},
	{value: 'NSV', text: 'Närståendevård'},
	{value: 'NSH', text: 'Närståendevård HIV-smittad'},
	{value: 'ÖVR', text: 'Annan semestergrundande frånvaro'},
	{value: 'TJL', text: 'Tjänstledighet hela dagar (ej sem.grund)'},
	{value: 'TJT', text: 'Tjänstledighet del av dag (ej sem.grund)'},
	{value: 'DIV', text: 'Annan frånvaro hela dagar (ej sem.grund)'},
	{value: 'DIT', text: 'Annan frånvaro del av dag (ej sem.grund)'},
];

export const SemUttagTyp = [
	{value: 'BET', text: 'Betalda dagar'},
	{value: 'OBE', text: 'Obetalda dagar'},
	{value: 'SPA', text: 'Sparade dagar'},
	{value: 'FÖR', text: 'Förskottsdagar'},
	{value: 'LÖN', text: 'Semesterlön i kr'},
	{value: 'TIL', text: 'Separat tillägg'},
	{value: 'ERS', text: 'Semesterersättning'},
	{value: 'NYA', text: 'Intjänade dagar'},
	{value: 'KON', text: 'Endast kontering'},
];

export const FerUttagTyp = [
	{value: 'MÅN', text: 'Månadslön'},
	{value: 'DAG', text: 'Ferielönsdag'},
	{value: 'RED', text: 'Avdrag ferielön'},
	{value: 'TIL', text: 'Semestertillägg'},
];

export const UphUttagTyp = [
	{value: 'LON', text: 'Uppehållslön'},
	{value: 'TIL', text: 'Uppehållslönetillägg'},
];

export const ArbGivTidTyp = [
	{value: 'ARB', text: 'Arbetad tid'},
	{value: 'FRA', text: 'Frånvarotid'},
	{value: 'FRN', text: 'Frånvarotid, minskar närvaro'},
	{value: 'ÖTI', text: 'Övertid'},
	{value: 'MER', text: 'Mertid'},
];

export const ArbGivErsTyp = [
	{value: 'SJE', text: 'Sjuklön (utan timmar)'},
	{value: 'SJK', text: 'Sjuklön (med timmar)'},
	{value: 'BEE', text: 'Beredskap/Jour (utan timmar)'},
	{value: 'BER', text: 'Beredskap/Jour (med timmar)'},
	{value: 'OTE', text: 'Övertidstillägg'},
	{value: 'MER', text: 'Mertid'},
	{value: 'OBE', text: 'OB-tillägg'},
	{value: 'TIL', text: 'Lönetillägg'},
	{value: 'SEM', text: 'Semesterersättning'},
	{value: 'GAG', text: 'Gage /lön per uppdrag'},
	{value: 'OVR', text: 'Andra förmåner'},
];

export const LSSBeslut = [
	{value: 'AUT', text: 'Automatiskt val'},
	{value: 'FSK', text: 'Försäkringskassan'},
	{value: 'KOM', text: 'Kommun'},
	{value: 'TUT', text: 'Tillfälligt utökat'},
	{value: 'SJU', text: 'Sjuklön'},
];

export const LSSDagVerkTyp = [
	{value: 'DAG', text: 'Daglig verksamhet'},
	{value: 'OMS', text: 'Barnomsorg'},
	{value: 'SKO', text: 'Skola'},
];

export const LSSTimTyp = [
	{value: 'ASS', text: 'Assistans'},
	{value: 'JOR', text: 'Jour'},
	{value: 'BER', text: 'Beredskap'},
];

export const LosenLonTyp = [
	{value: 'MÅN', text: 'Månadslön'},
	{value: 'TIM', text: 'Timlön'},
	{value: 'PRE', text: 'Prestationslön'},
	{value: 'UTÖ', text: 'Utbetalt överskott'},
	{value: 'TIL', text: 'Lönetillägg'},
	{value: 'RTL', text: 'Rolltillägg (tid)'},
	{value: 'RTP', text: 'Rolltillägg (prestationslön)'},
	{value: 'AKT', text: 'Aktivitetstillägg (tid)'},
	{value: 'AKP', text: 'Aktivitetstillägg (prestationslön)'},
	{value: 'KPT', text: 'Kompetenstillägg (tid)'},
	{value: 'KPP', text: 'Kompetenstillägg (prestationslön)'},
	{value: 'AST', text: 'Ansvarstillägg (tid)'},
	{value: 'ASP', text: 'Ansvarstillägg (prestationslön)'},
	{value: 'AVD', text: 'Löneavdrag'},
	{value: 'OBT', text: 'OB-tillägg'},
	{value: 'ÖVT', text: 'Övertidstillägg'},
];

// https://losenrapportering.se/Home/Support
const LosenBefattnKodIndexed = {
	'711112': 'Träarbetare/snickare',
	'711114': 'Timmringsarbetare ',
	'711205': 'Murare',
	'711215': 'Plattsättare',
	'711220': 'Putsarbetare',
	'711222': 'Ugnsmurare (eld- och syrafast murning och industriell ugnsmurning)',
	'711224': 'Stuckatör',
	'711226': 'Terazzoarbetare/Betonggolvslipare',
	'711228': 'Stenmontör',
	'711305': 'Betongarbetare',
	'711310': 'Håltagare',
	'711312': 'Hårdbetonggjutare',
	'711314': 'Mönsterbetonggjutare',
	'711316': 'Betongarbetare (Hydrualisk formflyttning)',
	'711318': 'Betongarbetare (blästring och bilning)',
	'711412': 'Markarbetare',
	'711420': 'Stensättare',
	'711500': 'Anläggningsdykare',
	'711505': 'Dykarskötare',
	'711600': 'Ställningsbyggare',
	'711605': 'Stämpmontör',
	'711610': 'Hissmontör (bygghiss)',
	'711910': 'Lärlingar',
	'711920': 'Flytspacklare',
	'711922': 'Förrådsväggmontör',
	'711924': 'Elementmontör (med kran eller maskin)',
	'711926': 'Sportgolvsmontör/idrottsinredningmontör',
	'711928': 'Byggservicearbetare',
	'711930': 'Mjukfogare (elastisk fogning)',
	'711932': 'Brandtätningsarbetare',
	'711934': 'Reliningarbetare',
	'712105': 'Tak- och tätskiktsmontör',
	'712110': 'Undertaksmontör',
	'712115': 'Sedumtaksläggare',
	'712200': 'Golvläggare',
	'712205': 'Epoxigolvläggare',
	'712305': 'Sprutisolerare (Lösfyllnads- och skumisolerare)',
	'713415': 'Demonteringsarbetare (Rivning och Sanering)',
	'713420': 'Asbestsanerare',
	'722105': 'Förrådssmidesarbetare',
	'722110': 'Byggnadssmed',
	'723300': 'Underhållsmekaniker och maskinreparatörer',
	'811105': 'Bergarbetare',
	'811200': 'Processoperatörer, stenkross- och malmförädlingsanläggning',
	'811305': 'Brunnsborrare (Bygg och anläggning)',
	'811500': 'Bergsprängare',
	'833205': 'Bil, traktor och dumperförare',
	'833210': 'Betongpumpförare',
	'833215': 'Lastbilsförare',
	'833220': 'Bilförare',
	'834130': 'Traktorförare (Bygg & Anläggning)',
	'834200': 'Anläggningsmaskinförare, mobila arbetsmaskiner',
	'834205': 'Anläggningsmaskinförare',
	'834210': 'Bandlastarförare',
	'834212': 'Bandschaktarförare',
	'834214': 'Grundläggningsmaskinförare',
	'834216': 'Rivningsarbetare med kran eller maskin',
	'834305': 'Mobilkranförare',
	'834310': 'Tornkranförare',
	'834400': 'Truckförare',
	'911335': 'Städpersonal (Bygg och anläggning)',
	'931002': 'Övrig förare',
	'931005': 'Övriga byggnads- och anläggningsarbetare',
	'931020': 'Förrådsarbetare',
	'931025': 'Övriga arbetare',
	'933230': 'Tunghanterare inom bygg',
	'933232': 'Bygglogistikarbetare',
};

export const LosenBefattnKod = Object.keys(LosenBefattnKodIndexed)
	.map((key) => ({
		value: key,
		text:  LosenBefattnKodIndexed[key],
	}))
	.sort((a, b) => parseInt(a.value, 10) < parseInt(b.value, 10));

export const KompFlexsaldoTyp = [
	{value: '+', text: 'Ökar saldot'},
	{value: '-', text: 'Minskar saldot'},
];

export const KompsaldoTyp = [
	{value: '+', text: 'Ökar kompsaldot'},
	{value: '-', text: 'Minskar kompsaldot'},
];

export const LSSKollAvtal = [
	{value: 'KFO', text: 'Fremia'},
	{value: 'KFS', text: 'KFS'},
	{value: 'HÖK', text: 'HÖK/AB (SKL)'},
	{value: 'PAN', text: 'PAN (SKL)'},
	{value: 'VFG', text: 'Vårdföretagarna, bransch G'},
	{value: 'EGT', text: 'Annat'},
	{value: 'NEJ', text: 'Inget kollektivavtal'},
];

export const BankNamn = [
	{value: 'ISO', text: 'ISO 20022'},
	{value: 'BGC', text: 'Bankgirot KI'},
	{value: 'HBK', text: 'Handelsbanken Löner'},
	{value: 'BGL', text: 'Bankgirot LB'},
	{value: 'HBL', text: 'Handelsbanken LB'},
	{value: 'SEB', text: 'S E B (gammal)'},
	{value: 'FSB', text: 'Swedbank (gammal)'},
	{value: 'SBB', text: 'Swedbank Bas'},
	{value: 'SBI', text: 'Swedbank Bas (internationell)'},
	{value: 'SBU', text: 'Swedbank företaget registerhållare'},
	{value: 'SFI', text: 'Swedbank företaget registerhållare (internationell)'},
	{value: 'MNB', text: 'Nordea (gammal)'},
	{value: 'NLU', text: 'Nordea'},
];

export const BokfProg = [
	{value: 'SIE4', text: 'SIE/4 fil'},
	{value: 'FNX', text: 'Fortnox Bokföring'},
	{value: 'PROC', text: 'Procountor Bokföring'},
	{value: 'VEAC', text: 'Visma E-ekonomi'},
	{value: 'FLAT', text: 'Textfil'},
	{value: 'SAP4', text: 'SAP Business One'},
	{value: 'MAGB', text: 'Magenta Bokföring'},
	{value: 'CORB', text: 'Corbis'},
	{value: 'EDIS', text: 'Edison'},
	{value: 'SE1P', text: 'SAP E1P'},
	{value: 'FRID', text: 'FRIDA'},
];

export const AmfTyp = [
	{value: '-', text: 'Ingår ej i rapportering'},
	{value: 'A', text: 'Arbetare'},
	{value: 'A51', text: 'Teknikavtalet IF Metall'},
	{value: 'A52', text: 'TEKO avtalet'},
	{value: 'A53', text: 'Livsmedelsavtalet'},
	{value: 'A54', text: 'Tobaksavtalet'},
	{value: 'A55', text: 'Avtalet för vin- och spritindustrin'},
	{value: 'A56', text: 'Kafferosterier och kryddfabriker'},
	{value: 'A57', text: 'Byggnadsämnesindustri'},
	{value: 'A58', text: 'Buteljglasindustrin'},
	{value: 'A59', text: 'Motorbranchavtalet'},
	{value: 'A60', text: 'Industriavtalet'},
	{value: 'A61', text: 'Läder & Sportartiklar'},
	{value: 'A62', text: 'Kemiska fabriker'},
	{value: 'A63', text: 'Glasindustrin'},
	{value: 'A64', text: 'Gemensamma Metall'},
	{value: 'A65', text: 'Explosivämnesindustrin'},
	{value: 'A66', text: 'I-avtalet (Allokemisk industri)'},
	{value: 'A67', text: 'Återvinningsföretag'},
	{value: 'A68', text: 'Tvättindustrin'},
	{value: 'A69', text: 'Stenindustrin'},
	{value: 'A70', text: 'Oljeraffinaderier'},
	{value: 'A71', text: 'Sockerindustrin (Nordic Sugar AB)'},
	{value: 'A72', text: 'IMG-avtalet'},
	{value: 'A73', text: 'Sågverksavtalet'},
	{value: 'A74', text: 'Skogsavtalet'},
	{value: 'A75', text: 'Virkesmätning'},
	{value: 'A76', text: 'Stoppmöbelindustrin'},
	{value: 'A77', text: 'Träindustri'},
	{value: 'A78', text: 'Infomediaavtalet'},
	{value: 'A79', text: 'Förpackningsavtalet'},
	{value: 'A80', text: 'Ahlsell Gelia/Sv. Foder/Teknosan'},
	{value: 'A81', text: 'Handels- & Metallavtalet'},
	{value: 'A82', text: 'Studsviksavtalet'},
	{value: 'A83', text: 'Flygtekniker med typcertifikat (medarbetaravtal)'},
	{value: 'A84', text: 'Massa- och pappersindustrin'},
	{value: 'A85', text: 'Stål- och metallindustrin blå avtalet'},
	{value: 'A86', text: 'Tidningsavtalet'},
	{value: 'A87', text: 'Gruventreprenadavtalet'},
	{value: 'A88', text: 'Trävaruhandelsavtalet'},
	{value: 'A3', text: 'Måleriyrket'},
	{value: 'A91', text: 'Installationsavtalet'},
	{value: 'A92', text: 'Kraftverksavtalet'},
	{value: 'A93', text: 'Elektroskandiaavtalet'},
	{value: 'A11', text: 'Bemanningsföretag'},
	{value: 'A12', text: 'Byggavtalet'},
	{value: 'A13', text: 'Dalslands kanal'},
	{value: 'A14', text: 'Detaljhandeln'},
	{value: 'A15', text: 'Entreprenadmaskinavtalet'},
	{value: 'A16', text: 'Glasmästeriavtalet'},
	{value: 'A17', text: 'Göta kanalbolag AB'},
	{value: 'A18', text: 'Lageravtalet'},
	{value: 'A19', text: 'Lager- och E-handelsavtalet'},
	{value: 'A20', text: 'Lagerpersonal vid glassföretag, filialer och depålager samt direktsäljer'},
	{value: 'A21', text: 'Larm- och säkerhetsteknikavtalet'},
	{value: 'A22', text: 'Maskinföraravtalet'},
	{value: 'A23', text: 'Plåt- och ventilationsavtalet'},
	{value: 'A24', text: 'Privatteateravtalet (medarbetaravtal)'},
	{value: 'A25', text: 'Städavtalet'},
	{value: 'A26', text: 'Teknikinstallation VVS och Kyl'},
	{value: 'A41', text: 'TRIA Skola & Vård'},
	{value: 'A27', text: 'Restaurang- och caféanställda'},
	{value: 'A28', text: 'Skärgårdstrafik ASL'},
	{value: 'A42', text: 'STANLEY Security'},
	{value: 'A29', text: 'Värdepapper'},
	{value: 'A30', text: 'Väg- och banavtalet'},
	{value: 'A43', text: 'Danscentrumavtalet'},
	{value: 'A44', text: 'Teatercentrumavtalet'},
	{value: 'A45', text: 'Kollektivavtalet för Radio-, TV-, Data- och Elektroniktekniker'},
	{value: 'A46', text: 'Spårtrafik'},
	{value: 'T', text: 'Tjänsteman'},
	{value: 'T6', text: 'Tjänsteman, Anställd VD'},
];

export const BilBiltyp = [
	{value: 'BEN', text: 'Bensin'},
	{value: 'DIS', text: 'Diesel'},
	{value: 'EL', text: 'El'},
	{value: 'GAS', text: 'Gas'},
	{value: 'LPG', text: 'Gasol'},
	{value: 'ALK', text: 'Alkohol'},
	{value: 'HYB', text: 'Elhybrid'},
	{value: 'OVR', text: 'Övrigt'},
];

export const Roll = [
	{value: 'ADM', text: 'Systemadministratör'},
	{value: 'FTG', text: 'Företagsadministratör'},
	{value: 'AVD', text: 'Avdelningsansvarig'},
	{value: 'LSS', text: 'Kundansvarig'},
	{value: 'BRU', text: 'Brukare'},
	{value: 'PER', text: 'Anställd'},
];

export const LoginType = [
	{value: 'F', text: 'Enligt företagets inställningar'},
	{value: 'S', text: 'Tvåstegsverifering (SMS)'},
	{value: 'B', text: 'BankID'},
	{value: 'A', text: 'Användarnamn & lösenord'},
];

export const KalKarensRegel = [
	{value: 'KAR', text: 'Karenstimmar'},
	{value: 'KFO', text: 'Fremia'},
	{value: 'LSS', text: 'Vårdföretagarna'},
];

export const SjukFranvaroTyp = [
	{value: 'K', text: 'Karensdag'},
	{value: 'L', text: 'Sjuklön'},
	{value: 'E', text: 'Sjukersättning'},
	{value: 'P', text: 'Sjukpenning'},
];

export const SafLonForm = [
	{value: '1', text: 'Månadslön'},
	{value: '2', text: 'Veckolön'},
	{value: '3', text: 'Timlön'},
];

export const SafArbTidsart = [
	{value: '1', text: '1 - Dagarbete'},
	{value: '2', text: '2 - 2-skiftsarbete'},
	{value: '3', text: '3 - Intermittent 3-skiftsarbete'},
	{value: '4', text: '4 - Kontinuerligt 3-skiftsarbete'},
	{value: '5', text: '5 - Underjordsarbete'},
	{value: '6', text: '6 - Ständigt nattskift'},
];

export const SafPersKat = [
	{value: '1', text: 'Arbetare'},
	{value: '2', text: 'Stationär tjänsteman'},
	{value: '3', text: 'Entrepenad-tjänsteman'},
	{value: '4', text: 'Ambulerande tjänsteman (garantilön 133 timmar)'},
	{value: '5', text: 'Ambulerande tjänsteman (garantilön 150 timmar)'},
	{value: '6', text: 'Ambulerande tjänsteman'},
]

export const HandelseTyp = [
	{value: 'AV', text: 'Avanmälan'},
	{value: 'NY', text: 'Nyanmälan'},
];

export const HandelseKod = [
	{value: 0, text: ''},
	{value: 1, text: 'Avslutad anställning'},
	{value: 2, text: 'Föräldraledig'},
	// {value: 3, text: 'Tjänstledig'},
	{value: 9, text: 'Annulering'},
];

export const ITPPlan = [
	{value: 'AUTO', text: 'Automatiskt beroende på ålder'},
	{value: 'ITP1', text: 'ITP 1 för alla'},
	{value: 'PERS', text: 'Val per person'},
	{value: 'NONE', text: 'Ej ITP'},
];

export const ITPPlanPer = [
	{value: 'ITP1', text: 'ITP 1 - premiebestämd'},
	{value: 'ITP2', text: 'ITP 2 - förmånsbestämd'},
	{value: 'NONE', text: 'Ej ITP'},
];

export const ITP2ArbOformaga = [
	{value: '0.75', text: '75%'},
	{value: '0.5', text: '50%'},
	{value: '0.25', text: '25%'},
	{value: '0.0', text: '0%'},
];

export const Dimensioner = [
	{value: '1', text: '1 - Kostnadsställe'},
	{value: '2', text: '2 - Kostnadsbärare'},
	{value: '3', text: '3 - Reserv'},
	{value: '4', text: '4 - Reserv'},
	{value: '5', text: '5 - Reserv'},
	{value: '6', text: '6 - Projekt'},
	{value: '7', text: '7 - Anställningsnummer'},
	{value: '8', text: '8 - Kund'},
	{value: '9', text: '9 - Leverantör'},
	{value: '10', text: '10 - Faktura'},
	{value: '11', text: '11 - Reserv'},
	{value: '12', text: '12 - Reserv'},
	{value: '13', text: '13 - Reserv'},
	{value: '14', text: '14 - Reserv'},
	{value: '15', text: '15 - Reserv'},
	{value: '16', text: '16 - Reserv'},
	{value: '17', text: '17 - Reserv'},
	{value: '18', text: '18 - Reserv'},
	{value: '19', text: '19 - Reserv'},
];

export const DimensionerSFSP = [
	{value: '1', text: '1 - Kostnadsställe'},
	{value: '2', text: '2 - Projektkod'},
	{value: '3', text: '3 - Produktkod'},
	{value: '4', text: '4 - Aktivitet'},
	{value: '5', text: '5 - Free2'},
];

export const DimensionerFRID = [
	{value: '1', text: '1 - Kostnadsställe'},
	{value: '2', text: '2 - Aktivitet'},
	{value: '3', text: '3 - Finansiär'},
	{value: '4', text: '4 - Resultatkod'},
	{value: '5', text: '5 - Avdelning'},
	{value: '6', text: '6 - K/I-grupp'},
];

export const SafJobbstatus = [
	{value: '0', text: 'Övriga anställda'},
	{value: '1', text: 'Chef'},
	{value: '2', text: 'VD'},
	{value: '3', text: 'Arbetsledare'},
	{value: '4', text: 'Förman'},
	{value: '5', text: 'Lärling'},
];

export const SorteringPersonallista = [
	{value: 'AnstNr', text: 'Anställningsnummer'},
	{value: 'EfNamnFoNamn', text: 'Efternamn, Förnamn'},
	{value: 'FoNamnEfNamn', text: 'Förnamn, Efternamn'},
	{value: 'PersNr', text: 'Personnummer'},
];

export const SemDagarTyp = [
	{value: 'NET', text: 'Nettodagar'},
	{value: 'BRU', text: 'Semesterfaktor'},
	{value: 'TIM', text: 'Timsemester'},
];

export const SemUndTyp = [
	{value: 'INTJ', text: 'Tider och ersättningar från intjänandeperiod', visible: true},
	{value: 'LONUTB', text: 'Tider från intjänandeperiod, ersättningar från utbetalningsdatum', visible: true},
	{value: 'KONTAN', text: 'Kontantprincipen', visible: true},
].filter((item) => item.visible);

export const SemTidsEnhet = [
	{value: 'DGR', text: 'Dagar'},
	{value: 'TIM', text: 'Timmar'},
];

export const SemGruSjukdom = [
	{value: 'LAG', text: 'Enligt lag'},
	{value: 'DEL', text: 'Enligt lag samt deltid'},
	{value: 'ALL', text: 'All sjukfrånvaro'},
];

export const BokfdatLon = [
	{value: 'U', text: 'Utbetalningsdatum'},
	{value: 'E', text: 'Intjänandeperioden'},
];

export const BokfdatSkatt = [
	{value: 'L', text: 'Löneperioden'},
	{value: 'S', text: 'Skattedatum'},
];

export const PerKontaktRelation = [
	{value: 'BARN', text: 'Barn'},
	{value: 'PARTNER', text: 'Partner'},
	{value: 'FÖRÄLDER', text: 'Förälder'},
	{value: 'SYSKON', text: 'Syskon'},
	{value: 'SLÄKT', text: 'Släkting'},
	{value: 'ANNAN', text: 'Annan'},
];

export const SlutatOrsak = [
	{value: 'ARB', text: 'Uppsägning p.g.a arbetsbrist'},
	{value: 'TID', text: 'Avslutad tidsbegränsad anställning'},
	{value: 'BEG', text: 'Den anställdes egen begäran'},
	{value: 'KON', text: 'Konkurs'},
	{value: 'PER', text: 'Annan orsak'},
];

export const KostnSlag = [
	{value: 'KRI', text: 'Kringkostnader'},
	{value: 'KVA', text: 'Kvarstående medel'},
	{value: 'ADM', text: 'Administration'},
	{value: 'UTB', text: 'Utbildningskostnader'},
	{value: 'AMI', text: 'Arbetsmiljöinsatser'},
	{value: 'PER', text: 'Personalomkostnader'},
	{value: 'ASS', text: 'Assistansomkostnader'},
	{value: 'LÖN', text: 'Lönekostnader'},
];

export const CadmTyp = [
	{value: 'KRONOR', text: 'Kronor'},
	{value: 'PRCSCH', text: 'Procent av schablonbelopp'},
	{value: 'PRCBEV', text: 'Procent av beviljat belopp'},
];

export const Sjklonkostn = [
	{value: 'NOCADM', text: 'Utan administration'},
	{value: 'SCHABL', text: 'Schablonbelopp'},
	{value: 'TOTALT', text: 'Total kostnad'},
];

export const Uskr3057Selection = [
	{value: 'VRD', text: 'Vårdnadshavare'},
	{value: 'GOD', text: 'God man'},
	{value: 'FRV', text: 'Förvaltare'},
];

export const FKKoptAterbet = [
	{value: 'DRA', text: 'Försäkringskassan drar av för mycket utbetald ersättning'},
	{value: 'PRV', text: 'Försäkringskassan prövar om jag är återbetalningsskyldig'},
];

export const ArbAvgUts = [
	{value: '1A1B', text: 'till Kanada, USA, Indien, Sydkorea (1)'},
	{value: '1C1D', text: 'till Québec, Filippinerna (1)'},
	{value: '2A2B', text: 'från USA, Kanada, Sydkorea (2 sv.utbet)'},
	{value: '2C2D', text: 'från Québec (2 sv.utbet)'},
	{value: '3A3B', text: 'från Kanada, USA, Indien, Sydkorea (3)'},
	{value: '3C3D', text: 'från Québec, Filippinerna (3)'},
	{value: '4A4B', text: 'till Kanada, Sydkorea (4 sv.koncern)'},
	{value: '4C4D', text: 'till Québec (4 sv.koncern)'},
];

export const StdPayslipLocale = [
	{value: 'SV', text: 'Svenska'},
	{value: 'EN', text: 'Engelska'},
	{value: 'FR', text: 'Franska'},
	{value: 'PL', text: 'Polska'},
];

export const GenderOptions = [
	{value: 'M', text: 'Man'},
	{value: 'F', text: 'Kvinna'},
	{value: 'U', text: 'Ospecificerat'},
];

export const ITPForlangdTyp = [
	{value: 'NEJ', text: 'Ingen ITP efter 65 år'},
	{value: 'E65', text: 'ITP 1 efter 65 år'},
	{value: 'E66', text: 'ITP 1 efter 66 år'},
];

export const KUBlankett = [
	{value: 'KU10S', text: 'KU10 - Kontrolluppgift från arbetsgivare m.fl.'},
	{value: 'KU10E', text: 'KU10 - Income statement from employers etc.'},
	{value: 'KU13S', text: 'KU13 - Särskild inkomstskatt för utomlands bosatta (SINK)'},
	{value: 'KU13E', text: 'KU13 - Special income tax for non-residents (SINK)'},
	{value: 'KU14S', text: 'KU14 - Pensionsgrundande inkomst och utbyte av kontrolluppgifter'},
	{value: 'KU14E', text: 'KU14 - Pensionable income and exchange of income statements'},
];

export const ForaReportType = [
	{value: 'MONTHLY', text: 'Månadsrapportering'},
	{value: 'YEARLY', text: 'Årsredovisning'},
	{value: 'NONE', text: 'Ej tecknad'},
];

export const ForaAvailableParameters = [
	{value: 'TILLVAL_ASL65', text: 'Avtalspension SAF-LO 65+'},
	{value: 'TILLVAL_KP65', text: 'Kompletterande Pension 65+'},
];

export const FerAvtalKod = [
	{value: 'KFO', text: 'Ferielön enligt Fremia'},
	{value: 'ALM', text: 'Almega friskoleavtal'},
];

export const BrukareNamnTyp = [
	{value: 'BRUKARE', text: 'Brukare'},
	{value: 'ARBETSLEDARE', text: 'Arbetsledare'},
	{value: 'KUND', text: 'Kund'},
];

export const SKVLandskoder = [
	{
		'value': 'AF',
		'text':  'Afghanistan',
	},
	{
		'value': 'AL',
		'text':  'Albanien',
	},
	{
		'value': 'DZ',
		'text':  'Algeriet',
	},
	{
		'value': 'AS',
		'text':  'Amerikanska Samoa',
	},
	{
		'value': 'AD',
		'text':  'Andorra',
	},
	{
		'value': 'AO',
		'text':  'Angola',
	},
	{
		'value': 'AI',
		'text':  'Anguilla',
	},
	{
		'value': 'AQ',
		'text':  'Antarktis',
	},
	{
		'value': 'AG',
		'text':  'Antigua och Barbuda',
	},
	{
		'value': 'AR',
		'text':  'Argentina',
	},
	{
		'value': 'AM',
		'text':  'Armenien',
	},
	{
		'value': 'AW',
		'text':  'Aruba',
	},
	{
		'value': 'AU',
		'text':  'Australien',
	},
	{
		'value': 'AZ',
		'text':  'Azerbajdzjan',
	},
	{
		'value': 'BS',
		'text':  'Bahamas',
	},
	{
		'value': 'BH',
		'text':  'Bahrain',
	},
	{
		'value': 'BD',
		'text':  'Bangladesh',
	},
	{
		'value': 'BB',
		'text':  'Barbados',
	},
	{
		'value': 'BE',
		'text':  'Belgien',
	},
	{
		'value': 'BZ',
		'text':  'Belize',
	},
	{
		'value': 'BJ',
		'text':  'Benin',
	},
	{
		'value': 'BM',
		'text':  'Bermuda',
	},
	{
		'value': 'BT',
		'text':  'Bhutan',
	},
	{
		'value': 'BO',
		'text':  'Bolivia',
	},
	{
		'value': 'BA',
		'text':  'Bosnien och Hercegovina',
	},
	{
		'value': 'BW',
		'text':  'Botswana',
	},
	{
		'value': 'BV',
		'text':  'Bouvetön',
	},
	{
		'value': 'BR',
		'text':  'Brasilien',
	},
	{
		'value': 'IO',
		'text':  'Brittiska Indiska Oceanöarna',
	},
	{
		'value': 'BN',
		'text':  'Brunei Darussalam',
	},
	{
		'value': 'BG',
		'text':  'Bulgarien',
	},
	{
		'value': 'BF',
		'text':  'Burkina Faso',
	},
	{
		'value': 'BI',
		'text':  'Burundi',
	},
	{
		'value': 'KY',
		'text':  'Caymanöarna',
	},
	{
		'value': 'CF',
		'text':  'Centralafrikanska republiken',
	},
	{
		'value': 'CL',
		'text':  'Chile',
	},
	{
		'value': 'CO',
		'text':  'Colombia',
	},
	{
		'value': 'CK',
		'text':  'Cooköarna',
	},
	{
		'value': 'CR',
		'text':  'Costa Rica',
	},
	{
		'value': 'CY',
		'text':  'Cypern',
	},
	{
		'value': 'DK',
		'text':  'Danmark',
	},
	{
		'value': 'DJ',
		'text':  'Djibouti',
	},
	{
		'value': 'DM',
		'text':  'Dominica',
	},
	{
		'value': 'DO',
		'text':  'Dominikanska republiken',
	},
	{
		'value': 'EC',
		'text':  'Ecuador',
	},
	{
		'value': 'EG',
		'text':  'Egypten',
	},
	{
		'value': 'GQ',
		'text':  'Ekvatorialguinea',
	},
	{
		'value': 'SV',
		'text':  'El Salvador',
	},
	{
		'value': 'CI',
		'text':  'Elfenbenskusten',
	},
	{
		'value': 'ER',
		'text':  'Eritrea',
	},
	{
		'value': 'EE',
		'text':  'Estland',
	},
	{
		'value': 'ET',
		'text':  'Etiopien',
	},
	{
		'value': 'FK',
		'text':  'Falklandsöarna',
	},
	{
		'value': 'FJ',
		'text':  'Fiji',
	},
	{
		'value': 'PH',
		'text':  'Filippinerna',
	},
	{
		'value': 'FI',
		'text':  'Finland',
	},
	{
		'value': 'FR',
		'text':  'Frankrike',
	},
	{
		'value': 'GF',
		'text':  'Franska Guyana',
	},
	{
		'value': 'PF',
		'text':  'Franska Polynesien',
	},
	{
		'value': 'FO',
		'text':  'Färöarna',
	},
	{
		'value': 'AE',
		'text':  'Förenade Arabemiraten',
	},
	{
		'value': 'GA',
		'text':  'Gabon',
	},
	{
		'value': 'GM',
		'text':  'Gambia',
	},
	{
		'value': 'GE',
		'text':  'Georgien',
	},
	{
		'value': 'GH',
		'text':  'Ghana',
	},
	{
		'value': 'GI',
		'text':  'Gibraltar',
	},
	{
		'value': 'GR',
		'text':  'Grekland',
	},
	{
		'value': 'GD',
		'text':  'Grenada',
	},
	{
		'value': 'GL',
		'text':  'Grönland',
	},
	{
		'value': 'GP',
		'text':  'Guadeloupe',
	},
	{
		'value': 'GU',
		'text':  'Guam',
	},
	{
		'value': 'GT',
		'text':  'Guatemala',
	},
	{
		'value': 'GG',
		'text':  'Guernsey',
	},
	{
		'value': 'GN',
		'text':  'Guinea, Republiken',
	},
	{
		'value': 'GW',
		'text':  'Guinea-Bissau',
	},
	{
		'value': 'GY',
		'text':  'Guyana',
	},
	{
		'value': 'HT',
		'text':  'Haiti',
	},
	{
		'value': 'HM',
		'text':  'Heardön och McDonaldöarna',
	},
	{
		'value': 'HN',
		'text':  'Honduras',
	},
	{
		'value': 'HK',
		'text':  'Hongkong',
	},
	{
		'value': 'IN',
		'text':  'Indien',
	},
	{
		'value': 'ID',
		'text':  'Indonesien',
	},
	{
		'value': 'IQ',
		'text':  'Irak',
	},
	{
		'value': 'IR',
		'text':  'Iran',
	},
	{
		'value': 'IE',
		'text':  'Irland, Eire',
	},
	{
		'value': 'IS',
		'text':  'Island',
	},
	{
		'value': 'IM',
		'text':  'Isle of Man',
	},
	{
		'value': 'IL',
		'text':  'Israel',
	},
	{
		'value': 'IT',
		'text':  'Italien',
	},
	{
		'value': 'JM',
		'text':  'Jamaica',
	},
	{
		'value': 'JP',
		'text':  'Japan',
	},
	{
		'value': 'YE',
		'text':  'Jemen',
	},
	{
		'value': 'JE',
		'text':  'Jersey',
	},
	{
		'value': 'JO',
		'text':  'Jordanien',
	},
	{
		'value': 'CX',
		'text':  'Julön',
	},
	{
		'value': 'VI',
		'text':  'Jungfruöarna (USA)',
	},
	{
		'value': 'VG',
		'text':  'Jungfruöarna, Brittiska',
	},
	{
		'value': 'KH',
		'text':  'Kambodja',
	},
	{
		'value': 'CM',
		'text':  'Kamerun',
	},
	{
		'value': 'CA',
		'text':  'Kanada',
	},
	{
		'value': 'CV',
		'text':  'Kap Verde',
	},
	{
		'value': 'KZ',
		'text':  'Kazakstan',
	},
	{
		'value': 'KE',
		'text':  'Kenya',
	},
	{
		'value': 'CN',
		'text':  'Kina',
	},
	{
		'value': 'KG',
		'text':  'Kirgizistan',
	},
	{
		'value': 'KI',
		'text':  'Kiribati',
	},
	{
		'value': 'CC',
		'text':  'Kokosöarna',
	},
	{
		'value': 'KM',
		'text':  'Komorerna',
	},
	{
		'value': 'CG',
		'text':  'Kongo',
	},
	{
		'value': 'CD',
		'text':  'Kongo, Demokratiska republiken (f.d. Zaire)',
	},
	{
		'value': 'HR',
		'text':  'Kroatien',
	},
	{
		'value': 'CU',
		'text':  'Kuba',
	},
	{
		'value': 'KW',
		'text':  'Kuwait',
	},
	{
		'value': 'LA',
		'text':  'Laos',
	},
	{
		'value': 'LS',
		'text':  'Lesotho',
	},
	{
		'value': 'LV',
		'text':  'Lettland',
	},
	{
		'value': 'LB',
		'text':  'Libanon',
	},
	{
		'value': 'LR',
		'text':  'Liberia',
	},
	{
		'value': 'LY',
		'text':  'Libyen',
	},
	{
		'value': 'LI',
		'text':  'Liechtenstein',
	},
	{
		'value': 'LT',
		'text':  'Litauen',
	},
	{
		'value': 'LU',
		'text':  'Luxemburg',
	},
	{
		'value': 'MO',
		'text':  'Macao',
	},
	{
		'value': 'MG',
		'text':  'Madagaskar',
	},
	{
		'value': 'MK',
		'text':  'Makedonien',
	},
	{
		'value': 'MW',
		'text':  'Malawi',
	},
	{
		'value': 'MY',
		'text':  'Malaysia',
	},
	{
		'value': 'MV',
		'text':  'Maldiverna',
	},
	{
		'value': 'ML',
		'text':  'Mali',
	},
	{
		'value': 'MT',
		'text':  'Malta',
	},
	{
		'value': 'MA',
		'text':  'Marocko',
	},
	{
		'value': 'MH',
		'text':  'Marshallöarna',
	},
	{
		'value': 'MQ',
		'text':  'Martinique',
	},
	{
		'value': 'MR',
		'text':  'Mauretanien',
	},
	{
		'value': 'MU',
		'text':  'Mauritius',
	},
	{
		'value': 'YT',
		'text':  'Mayotte',
	},
	{
		'value': 'MX',
		'text':  'Mexico',
	},
	{
		'value': 'FM',
		'text':  'Mikronesien',
	},
	{
		'value': 'MZ',
		'text':  'Moçambique',
	},
	{
		'value': 'MD',
		'text':  'Moldavien',
	},
	{
		'value': 'MC',
		'text':  'Monaco',
	},
	{
		'value': 'MN',
		'text':  'Mongoliet',
	},
	{
		'value': 'ME',
		'text':  'Montenegro',
	},
	{
		'value': 'MS',
		'text':  'Montserrat',
	},
	{
		'value': 'MM',
		'text':  'Myanmar/Burma',
	},
	{
		'value': 'NA',
		'text':  'Namibia',
	},
	{
		'value': 'NR',
		'text':  'Nauru',
	},
	{
		'value': 'NL',
		'text':  'Nederländerna/Holland',
	},
	{
		'value': 'AN',
		'text':  'Nederländska Antillerna',
	},
	{
		'value': 'NP',
		'text':  'Nepal',
	},
	{
		'value': 'NI',
		'text':  'Nicaragua',
	},
	{
		'value': 'NE',
		'text':  'Niger',
	},
	{
		'value': 'NG',
		'text':  'Nigeria',
	},
	{
		'value': 'NU',
		'text':  'Niue',
	},
	{
		'value': 'KP',
		'text':  'Nordkorea',
	},
	{
		'value': 'MP',
		'text':  'Nordmarianerna',
	},
	{
		'value': 'NF',
		'text':  'Norfolkön',
	},
	{
		'value': 'NO',
		'text':  'Norge',
	},
	{
		'value': 'NC',
		'text':  'Nya Kaledonien',
	},
	{
		'value': 'NZ',
		'text':  'Nya Zeeland',
	},
	{
		'value': 'OM',
		'text':  'Oman',
	},
	{
		'value': 'PK',
		'text':  'Pakistan',
	},
	{
		'value': 'PW',
		'text':  'Palau',
	},
	{
		'value': 'PS',
		'text':  'Palestina (Västbanken/Gazaremsan)',
	},
	{
		'value': 'PA',
		'text':  'Panama',
	},
	{
		'value': 'PG',
		'text':  'Papua Nya Guinea',
	},
	{
		'value': 'PY',
		'text':  'Paraguay',
	},
	{
		'value': 'PE',
		'text':  'Peru',
	},
	{
		'value': 'PN',
		'text':  'Pitcairn',
	},
	{
		'value': 'PL',
		'text':  'Polen',
	},
	{
		'value': 'PT',
		'text':  'Portugal',
	},
	{
		'value': 'PR',
		'text':  'Puerto Rico',
	},
	{
		'value': 'QA',
		'text':  'Qatar',
	},
	{
		'value': 'RE',
		'text':  'Reunion',
	},
	{
		'value': 'RO',
		'text':  'Rumänien',
	},
	{
		'value': 'RW',
		'text':  'Rwanda',
	},
	{
		'value': 'RU',
		'text':  'Ryssland',
	},
	{
		'value': 'BL',
		'text':  'Saint Barthélemy',
	},
	{
		'value': 'SH',
		'text':  'Saint Helena',
	},
	{
		'value': 'KN',
		'text':  'Saint Kitts och Nevis',
	},
	{
		'value': 'LC',
		'text':  'Saint Lucia',
	},
	{
		'value': 'MF',
		'text':  'Saint Martin',
	},
	{
		'value': 'TF',
		'text':  'Saint Paulöarna',
	},
	{
		'value': 'PM',
		'text':  'Saint Pierre och Miquélon',
	},
	{
		'value': 'VC',
		'text':  'Saint Vincent och Grenadinerna',
	},
	{
		'value': 'SB',
		'text':  'Salomonöarna',
	},
	{
		'value': 'WS',
		'text':  'Samoa',
	},
	{
		'value': 'SM',
		'text':  'San Marino',
	},
	{
		'value': 'ST',
		'text':  'São Tomé och Principe',
	},
	{
		'value': 'SA',
		'text':  'Saudiarabien',
	},
	{
		'value': 'CH',
		'text':  'Schweiz',
	},
	{
		'value': 'SN',
		'text':  'Senegal',
	},
	{
		'value': 'RS',
		'text':  'Serbien',
	},
	{
		'value': 'SC',
		'text':  'Seychellerna',
	},
	{
		'value': 'SL',
		'text':  'Sierra Leone',
	},
	{
		'value': 'SG',
		'text':  'Singapore',
	},
	{
		'value': 'SK',
		'text':  'Slovakien',
	},
	{
		'value': 'SI',
		'text':  'Slovenien',
	},
	{
		'value': 'UM',
		'text':  'Små avlägset belägna öar (USA)',
	},
	{
		'value': 'SO',
		'text':  'Somalia',
	},
	{
		'value': 'ES',
		'text':  'Spanien',
	},
	{
		'value': 'LK',
		'text':  'Sri Lanka',
	},
	{
		'value': 'GB',
		'text':  'Storbritannien och Nordirland',
	},
	{
		'value': 'SD',
		'text':  'Sudan',
	},
	{
		'value': 'SR',
		'text':  'Surinam',
	},
	{
		'value': 'SJ',
		'text':  'Svalbard och Jan Mayen',
	},
	{
		'value': 'SE',
		'text':  'Sverige',
	},
	{
		'value': 'SZ',
		'text':  'Swaziland',
	},
	{
		'value': 'ZA',
		'text':  'Sydafrika',
	},
	{
		'value': 'GS',
		'text':  'Sydgeorgien och Sydsandwichöarna',
	},
	{
		'value': 'KR',
		'text':  'Sydkorea',
	},
	{
		'value': 'SY',
		'text':  'Syrien',
	},
	{
		'value': 'TJ',
		'text':  'Tadjikistan',
	},
	{
		'value': 'TW',
		'text':  'Taiwan',
	},
	{
		'value': 'TZ',
		'text':  'Tanzania',
	},
	{
		'value': 'TD',
		'text':  'Tchad',
	},
	{
		'value': 'TH',
		'text':  'Thailand',
	},
	{
		'value': 'CZ',
		'text':  'Tjeckien',
	},
	{
		'value': 'TG',
		'text':  'Togo',
	},
	{
		'value': 'TK',
		'text':  'Tokelau',
	},
	{
		'value': 'TO',
		'text':  'Tonga',
	},
	{
		'value': 'TT',
		'text':  'Trinidad och Tobago',
	},
	{
		'value': 'TN',
		'text':  'Tunisien',
	},
	{
		'value': 'TR',
		'text':  'Turkiet',
	},
	{
		'value': 'TM',
		'text':  'Turkmenistan',
	},
	{
		'value': 'TC',
		'text':  'Turks och Caicosöarna',
	},
	{
		'value': 'TV',
		'text':  'Tuvalu',
	},
	{
		'value': 'DE',
		'text':  'Tyskland',
	},
	{
		'value': 'UG',
		'text':  'Uganda',
	},
	{
		'value': 'UA',
		'text':  'Ukraina',
	},
	{
		'value': 'HU',
		'text':  'Ungern',
	},
	{
		'value': 'UY',
		'text':  'Uruguay',
	},
	{
		'value': 'US',
		'text':  'USA',
	},
	{
		'value': 'UZ',
		'text':  'Uzbekistan',
	},
	{
		'value': 'VU',
		'text':  'Vanuatu',
	},
	{
		'value': 'VA',
		'text':  'Vatikanstaten',
	},
	{
		'value': 'VE',
		'text':  'Venezuela',
	},
	{
		'value': 'VN',
		'text':  'Vietnam',
	},
	{
		'value': 'BY',
		'text':  'Vitryssland/Belarus',
	},
	{
		'value': 'EH',
		'text':  'Västsahara',
	},
	{
		'value': 'WF',
		'text':  'Wallis och Futunaöarna',
	},
	{
		'value': 'ZM',
		'text':  'Zambia',
	},
	{
		'value': 'ZW',
		'text':  'Zimbabwe',
	},
	{
		'value': 'AX',
		'text':  'Åland',
	},
	{
		'value': 'AT',
		'text':  'Österrike',
	},
	{
		'value': 'TL',
		'text':  'Östtimor',
	},
];

export const CSVDELIMITEROPTIONS = [
	{value: ',', text: 'Kommatecken'},
	{value: ';', text: 'Semikolon'},
	{value: ' ', text: 'Blanksteg'},
	{value: '	', text: 'Tabb'},
];

export const CSVWRAPOPTIONS = [
	{value: '"', text: '" (Citattecken)'},
	{value: '\'', text: '\' (Apostrof)'},
	{value: '', text: 'Tomt (ingen avgränsare)'},
];

export const CSVCHARSETOPTIONS = [
	{value: 'windows-1252', text: 'ANSI (windows-1252)'},
	{value: 'utf-8', text: 'UTF-8'},
];

export default {};
